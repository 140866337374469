import React, { useState, useEffect } from "react";
import config from "../../config/config";

import { Claimreward, getUserInfo, getClaimInfo } from "./autopoolinfo";
import { toastAlert } from "../../helper/toastAlert";
import { saveAutoPool } from "../../Api/Actions";
import moment from "moment";
import { formatNumber } from "../../helper/custom";

export default function ClaimModalv2(props) {

    var { reload, walletConnection } = props;

    const [isDisable, setisDisable] = useState(false);
    const [txid, settxid] = useState("");
    const [showapprove, setshowapprove] = useState("");
    const [claimDetails, setclaimDetails] = useState({ fee: 0, allowance: 0, balanceOf: 0, symbol: "", lastClaimed: null, minimumwithdraw: 0 });

    useEffect(() => {
        loadInfo();
    }, [walletConnection]);

    async function loadInfo() {

        setshowapprove(false);
        if (walletConnection && walletConnection.address && walletConnection.address != "") {
            var { fee, allowance, balanceOf, symbol, lastClaimed, minimumwithdraw } = await getClaimInfo();
            setclaimDetails({ fee, allowance, balanceOf, symbol, lastClaimed, minimumwithdraw })
            if (allowance < fee) {
                setshowapprove(true);
            }
        }

    }


    async function claim() {

        setisDisable(true);
        var { isclaim, error, hash } = await Claimreward();
        if (error && error !== "") {
            toastAlert("error", error, 'claim');
        } else if (isclaim && hash) {
            settxid(hash);
            toastAlert("success", "Successfully Claimed");
            window.$("#autopool_claim_modal").modal('hide');
            window.$("#autopoolclaim_succes_modal").modal('show');

            var { user } = await getUserInfo();
            var data = {
                address: walletConnection.address,
                txtype: "claim",
                txid: hash,
                id: (user && user.id) ? user.id : 0
            }
            saveAutoPool(data);

            reload();
        } else {
            toastAlert("error", "Oops something went wrong", 'claim');
        }
        setisDisable(false)
    }

    return (
        <>
            <div
                className="modal fade primary_modal success"
                id="autopool_claim_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Confirm Claim
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                data-bs-backdrop="static"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="text-center">
                                    <button
                                        className="primary_btn mb-3"
                                        onClick={() => claim()}
                                        disabled={isDisable}
                                    >Claim</button>
                                    {isDisable && <i className="fas fa-spinner fa-spin my-2 d-block"></i>}

                                </div>
                                <span className="text-center d-flex justify-content-between px-3">
                                    <span> Fee:</span>
                                    <span> {formatNumber(claimDetails.fee, 5)} {claimDetails.symbol}
                                    </span>
                                </span>
                                <span className="text-center d-flex justify-content-between px-3">
                                    <span>  Minimum withdraw:</span>
                                    <span> {formatNumber(claimDetails.minimumwithdraw, 5)} USDT</span>
                                </span>

                                {claimDetails.lastClaimed &&
                                    <span className="text-center d-flex justify-content-between px-3">
                                        <span>    Next withdraw time: </span>
                                        <span>   {moment(claimDetails.lastClaimed).format("MMMM,Do YYYY hh:mm:a")}</span>
                                    </span>
                                }
                                {isDisable && <span className="text-center d-block">Please do not refresh the page, after confirm the payment.!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade primary_modal success"
                id="autopoolclaim_succes_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Successfully Claimed
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="mb-4">
                                    <svg className="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                    <a rel="noreferrer" href={config.txLink + "/tx/" + txid} target="_blank" className="d-block mt-3 text-center"> <i className="fa fa-eye" aria-hidden="true">
                                    </i> View Transaction </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
