import React, { useEffect, useState, memo } from 'react'
import Tree from "react-animated-tree-v2";
import { plus, minus, close } from "../../components/icons.js";
import { connection } from "../../helper/connection";
import config from "../../config/config";
import AUTOPOOLV3ABI from "../../ABI/AUTOPOOLV3.json"
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";



function Treemodelv3(props) {
    const { userdata } = props

    const [treedata, settreedata] = useState([])
    const [loading, setloading] = useState(false)
    const [searchdata, setsearchdata] = useState(userdata?.id)
    const [errorMgs, seterrorMgs] = useState(false)

    const walletConnection = useSelector((state) => state.walletConnection);

    const CustomizedIconTree = (props) => (
        <Tree
            icons={{ plusIcon: plus, minusIcon: minus, closeIcon: close }}
            {...props}
        />
    );


    useEffect(() => {
        getddetails(searchdata)
    }, [walletConnection.address, userdata, searchdata])


    const handleSearch = (e) => {
        const { value } = e.target
        console.log();
        if (value == "") {
            setsearchdata(userdata?.id)
        } else {
            setsearchdata(value)

        }

    }


    const getddetails = async (userId1) => {
        try {
            if (userId1 == undefined) {
                return false
            }
            setloading(true)
            seterrorMgs(false)
            var get = await connection();
            var web3 = get.web3;
            var address = get.address;

            var contractcall = new web3.eth.Contract(
                AUTOPOOLV3ABI,
                config.autoPoolcontractv3
            );
            let userAddress = await contractcall.methods.userList(userId1).call();

            if (userAddress != "0x0000000000000000000000000000000000000000") {
                let referaldata = await contractcall.methods.viewUserReferal(userAddress).call();
                let useraarr = []
                if (referaldata.length != 0) {
                    for (let data of referaldata) {
                        let users = await contractcall.methods.users(data).call();
                        useraarr.push({ id: users.id, address: data, parentid: userId1 })
                    }
                }
                await secondLevel(useraarr)
            } else {
                seterrorMgs(true)
            }


            // return useraarr

        } catch (err) {
            console.log(err);

        }
    }



    const secondLevel = async (data) => {
        try {
            settreedata(old => [...old, ...data]);
            setloading(false)

            if (data.length != 0) {
                for (let i = 0; i < data.length; i++) {
                    await getddetails(data[i].id)
                }
            }
            setloading(false)


        } catch (err) {
            console.log(err);

        }

    }


    const treestructure = (userLev1) => {

        const uniqueAuthors = treedata.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.id === current.id)) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);


        return (
            <>
                {uniqueAuthors && uniqueAuthors?.map((ele, i) => {
                    if (ele.parentid === userLev1)
                        return (
                            <>
                                <CustomizedIconTree
                                    onClick={() => { treestructure(ele.id) }}

                                    content={<span className="id">{ele?.id || 0}</span>}
                                    type={
                                        <img
                                            src={require("../../assets/images/user_3.png")}
                                            className="img-fluid"
                                            alt="Icon"
                                        />
                                    }
                                >
                                    {treestructure(ele.id)}
                                </CustomizedIconTree>
                            </>
                        );
                })}
            </>
        );
    };


    const validPositive = (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };


    return (
        <div className="bdr mb-4 bgcolor">
            <div className="t_flx mt-3">
                <h6>Genealogy Tree</h6>
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search ID"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={handleSearch}
                        onInput={validPositive}

                    />
                    <span class="input-group-text" id="basic-addon2">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </span>
                </div>
            </div>
            <div className="scroll">
                <div className="tree usr_tree overflow-hidden">
                    {!errorMgs ?
                        <>
                            {searchdata && searchdata != "" ? (
                                <>
                                    {loading == false &&
                                        <CustomizedIconTree

                                            open
                                            content={<span className="id">{searchdata || 0}</span>}
                                            type={
                                                <img
                                                    src={require("../../assets/images/user_3.png")}
                                                    className="img-fluid"
                                                    alt="Icon"
                                                />
                                            }
                                        >
                                            {treestructure(searchdata)}
                                        </CustomizedIconTree>}
                                    {loading && (
                                        <div className="text-center dashboardloader">
                                            <ReactLoading type={"bars"} color="#efcb46" className="loading" />
                                        </div>
                                    )}
                                </>

                            ) : (
                                <>
                                    {loading == false &&
                                        <CustomizedIconTree

                                            open
                                            content={<span className="id">{userdata?.id || 0}</span>}
                                            type={
                                                <img
                                                    src={require("../../assets/images/user_3.png")}
                                                    className="img-fluid"
                                                    alt="Icon"
                                                />
                                            }
                                        >
                                            {treestructure(userdata?.id)}
                                        </CustomizedIconTree>}
                                    {loading && (
                                        <div className="text-center dashboardloader">
                                            <ReactLoading type={"bars"} color="#efcb46" className="loading" />
                                        </div>
                                    )}
                                </>

                            )}
                        </> : <center>User ID Not Found</center>}
                </div>
            </div>
        </div>

    )
}

export default memo(Treemodelv3)