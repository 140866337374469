import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";

import { toastAlert } from "../../helper/toastAlert";
import { joinPool, getUserInfo } from "./autopoolinfo";
import { saveAutoPool } from "../../Api/Actions";

export default function JoinModal(props) {

    const navigate = useNavigate();

    var { reload, walletConnection, joinAmount } = props;

    const [isDisable, setisDisable] = useState(false);
    const [txid, settxid] = useState("");
    const [amount, setamount] = useState("");
    const [error, seterror] = useState("");

    async function submitJoin() {

        if (walletConnection && walletConnection.address == "") {
            window.$("#join_modal").modal('hide');
            navigate("/join-now");
            return;
        }

        setisDisable(true);


        var { isclaim, error, hash, amount } = await joinPool();
        if (error && error !== "") {
            toastAlert("error", error, 'joinpool');
        } else if (isclaim && hash) {
            settxid(hash);
            toastAlert("success", "Successfully joined");
            window.$("#join_modal").modal('hide');
            window.$("#join1_succes_modal").modal('show');
            setamount("");
            // var { user } = await getUserInfo();
            // var data = {
            //     address: walletConnection.address,
            //     txtype: "newuser",
            //     txid: hash,
            //     id: (user && user.id) ? user.id : 0,
            //     amount
            // }
            // saveAutoPool(data);
            reload();
        } else {
            toastAlert("error", "Oops something went wrong", 'joinpool');
        }
        setisDisable(false)
    }


    return (
        <>
            <div
                className="modal fade primary_modal success"
                id="join_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Confirm join
                            </h5>

                            {/* <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button> */}
                        </div>
                        <div className="modal-body">
                            <div>
                                <div class="w-100 input_box text-center mb-2">
                                    <span>Join amount {joinAmount} USDT</span>
                                </div>
                                <div className="text-center mt-3">
                                    <button
                                        className="primary_btn"
                                        onClick={() => submitJoin()}
                                        disabled={(isDisable || error) ? true : false}
                                    >Confirm</button>
                                    {isDisable && <i className="fas fa-spinner fa-spin"></i>}

                                </div>
                                {isDisable && <span className="text-center d-block">Please do not refresh the page, after confirm the payment.!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade primary_modal success"
                id="join1_succes_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Successfully joined
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="mb-4">
                                    <svg className="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                    <a rel="noreferrer" href={config.txLink + "/tx/" + txid} target="_blank" className="d-block mt-3 text-center"> <i className="fa fa-eye" aria-hidden="true">
                                    </i> View Transaction </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
