import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import Switch from "react-switch";

import "react-phone-input-2/lib/style.css";

import ConnectWallet from "../../components/ConnectWallet";

import { toastAlert } from "../../helper/toastAlert";

import { numberFloatOnly } from "../../helper/custom";
import {
    settingsInfo,
    updatePrice,
    updateClaimFee,
    updatewithdrawAmount,
    updateIsPause,
    checkUSDT,
    updateMinBalance,
    updateChild
} from "./settingsdetailv2"

export default function AutoPoolV2Settings(props) {

    const walletConnection = useSelector((state) => state.walletConnection);
    const navigate = useNavigate();


    const [isDisable1, setisDisable1] = useState(false);
    const [isDisable2, setisDisable2] = useState(false);
    const [isDisable3, setisDisable3] = useState(false);
    const [isDisable4, setisDisable4] = useState(false);
    const [isDisable5, setisDisable5] = useState(false);
    const [isDisable6, setisDisable6] = useState(false);

    const [tokenPrice, settokenPrice] = useState("");
    const [fee, setfee] = useState("");
    const [minwithdraw, setminwithdraw] = useState("");
    const [owneraddress, setowneraddress] = useState("");
    const [ispause, setispause] = useState(false);
    const [currentPause, setcurrentPause] = useState(false);
    const [minBal, setminBal] = useState(0);
    const [childCount, setchildCount] = useState(0);

    const [claimed, setclaimed] = useState(0);
    const [unclaimed, setunclaimed] = useState(0);

    useEffect(() => {
        loadSettings();
        //eslint-disable-next-line
    }, [walletConnection]);

    async function loadSettings() {
        if (walletConnection && walletConnection.address && walletConnection.address != "") {
            var {
                price,
                claimFee,
                minimum,
                owner,
                isPause,
                minContractBalance,
                childCount } = await settingsInfo();
            console.log(owner, 'ownerownerownerowner111', isPause)
            settokenPrice(price);
            setfee(claimFee);
            setminwithdraw(minimum);
            setowneraddress(owner);
            setispause(isPause);
            setcurrentPause(isPause);
            setminBal(minContractBalance);
            setchildCount(childCount);
        }


    }

    async function updateTokenPrice() {

        if (validation(tokenPrice)) {

            if (owneraddress && walletConnection.address && walletConnection.address.toLowerCase() == owneraddress.toLowerCase()) {
                setisDisable1(true)
                var { status, error } = await updatePrice(tokenPrice);
                console.log(status, "status");
                setisDisable1(false);
                if (status) {
                    toastAlert("success", "Successfully updated", 'settings')
                }
                else {
                    toastAlert("error", error, 'settings')
                }
            } else {
                toastAlert("error", "Please login into admin wallet", 'settings')
            }
        }

    }

    async function updateFee() {
        if (validation(fee)) {
            if (owneraddress && walletConnection.address && walletConnection.address.toLowerCase() == owneraddress.toLowerCase()) {
                setisDisable2(true)
                var { status, error } = await updateClaimFee(fee);
                setisDisable2(false);
                if (status) {
                    toastAlert("success", "Successfully updated", 'settings')
                } else {
                    toastAlert("error", error, 'settings')
                }
            } else {
                toastAlert("error", "Please login into admin wallet", 'settings')
            }
        }

    }

    function validation(amount) {
        var status = true;
        if (parseFloat(amount) == 0 || amount == "") {
            toastAlert("error", "Please enter valid price", 'settings')
            status = false;
        }
        return status;

    }

    const onChangeTokenPrice = async event => {

        var value = event.target.value;
        var status = numberFloatOnly(value);
        if (status) {
            settokenPrice(value)
        } else if (value === "" || !status) {
            settokenPrice("")
        }
    }
    const onChangefee = async event => {

        var value = event.target.value;
        var status = numberFloatOnly(value);
        if (status) {
            setfee(value)
        } else if (value === "" || !status) {
            setfee("")
        }
    }

    const handleChange = async checked => {
        setispause(checked);
    }

    async function UpdatePause() {
        if (currentPause != ispause) {
            if (owneraddress && walletConnection.address && walletConnection.address.toLowerCase() == owneraddress.toLowerCase()) {
                setisDisable4(true)
                var { status, error } = await updateIsPause(ispause);
                console.log(status, "status");
                setisDisable4(false);
                if (status) {
                    toastAlert("success", "Successfully updated", 'settings')
                } else {
                    toastAlert("error", error, 'settings')
                }
            } else {
                toastAlert("error", "Please login into admin wallet", 'settings')
            }
        } else {
            var msg = "Already contract is working";
            if (!currentPause) {
                msg = "Already contract is paused";
            }
            toastAlert("error", msg, 'settings')
        }

    }


    const onMinfee = async event => {

        var value = event.target.value;
        var status = numberFloatOnly(value);
        if (status) {
            setminBal(value)
        } else if (value === "" || !status) {
            setminBal("")
        }
    }

    async function updateMinimum() {
        if (validation(minBal)) {
            if (owneraddress && walletConnection.address && walletConnection.address.toLowerCase() == owneraddress.toLowerCase()) {
                setisDisable5(true)
                var { status, error } = await updateMinBalance(minBal);
                setisDisable5(false);
                if (status) {
                    toastAlert("success", "Successfully updated", 'settings')
                } else {
                    toastAlert("error", error, 'settings')
                }
            } else {
                toastAlert("error", "Please login into admin wallet", 'settings')
            }
        }

    }

    const onChild = async event => {

        var value = event.target.value;
        var status = numberFloatOnly(value);
        if (status) {
            setchildCount(value)
        } else if (value === "" || !status) {
            setchildCount("")
        }
    }


    async function updateChildVal() {
        if (validation(childCount)) {
            if (owneraddress && walletConnection.address && walletConnection.address.toLowerCase() == owneraddress.toLowerCase()) {
                setisDisable6(true)
                var { status, error } = await updateChild(childCount);
                setisDisable6(false);
                if (status) {
                    toastAlert("success", "Successfully updated", 'settings')
                } else {
                    toastAlert("error", error, 'settings')
                }
            } else {
                toastAlert("error", "Please login into admin wallet", 'settings')
            }
        }

    }



    return (
        <div className="t_flx py-5 mt-3 d-block text-center box" >
            <h6 className="mb-4 pb-2" >Settings AutoPoolV2</h6>
            <div className="row" >
                <div className="col-lg-6 text-start mx-auto" >
                    <label className="mb-2" >Token Price(1QBT={tokenPrice}$)</label>
                    <div class="input-group mt-0 mb-3 w-100">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Token Price"
                            aria-label="Token Price"
                            aria-describedby="basic-addon2"
                            onChange={onChangeTokenPrice}
                            value={tokenPrice}
                        />
                    </div>
                    <div className="d-flex mb-3" >
                        {walletConnection && walletConnection.address !== "" && walletConnection.connect === "yes" ?
                            <button
                                className="primary_btn"
                                disabled={isDisable1}
                                onClick={() => updateTokenPrice()}
                            >Update{isDisable1 && <i className="fas fa-spinner fa-spin"></i>}
                            </button>
                            :
                            <button
                                className="primary_btn"
                                data-bs-toggle="modal"
                                data-bs-target="#connect_wallet_modal"
                            >Update
                            </button>
                        }
                    </div>
                </div>
            </div>

            <div className="row" >
                <div className="col-lg-6 text-start mx-auto" >
                    <label className="mb-2" >Fee($)</label>
                    <div class="input-group mt-0 mb-3 w-100">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Fee"
                            aria-label="Fee"
                            aria-describedby="basic-addon2"
                            onChange={onChangefee}
                            value={fee}
                        />
                    </div>
                    <div className="d-flex mb-3" >
                        {walletConnection && walletConnection.address !== "" && walletConnection.connect === "yes" ?
                            <button
                                className="primary_btn"
                                disabled={isDisable2}
                                onClick={() => updateFee()}
                            >Update{isDisable2 && <i className="fas fa-spinner fa-spin"></i>}
                            </button>
                            :
                            <button
                                className="primary_btn"
                                data-bs-toggle="modal"
                                data-bs-target="#connect_wallet_modal"
                            >Update
                            </button>
                        }
                    </div>
                </div>
            </div>

            <div className="row" >
                <div className="col-lg-6 text-start mx-auto" >
                    <label className="mb-2" >Minimum Balance(Contract)</label>
                    <div class="input-group mt-0 mb-3 w-100">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Minimum Balance"
                            aria-label="Minimum Balance"
                            aria-describedby="basic-addon2"
                            onChange={onMinfee}
                            value={minBal}
                        />
                    </div>
                    <div className="d-flex mb-3" >
                        {walletConnection && walletConnection.address !== "" && walletConnection.connect === "yes" ?
                            <button
                                className="primary_btn"
                                disabled={isDisable5}
                                onClick={() => updateMinimum()}
                            >Update{isDisable5 && <i className="fas fa-spinner fa-spin"></i>}
                            </button>
                            :
                            <button
                                className="primary_btn"
                                data-bs-toggle="modal"
                                data-bs-target="#connect_wallet_modal"
                            >Update
                            </button>
                        }
                    </div>
                </div>
            </div>

            <div className="row" >
                <div className="col-lg-6 text-start mx-auto" >
                    <label className="mb-2" >Payout ratio</label>
                    <div class="input-group mt-0 mb-3 w-100">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Person Count"
                            aria-label="Person Count"
                            aria-describedby="basic-addon2"
                            onChange={onChild}
                            value={childCount}
                        />
                    </div>
                    <div className="d-flex mb-3" >
                        {walletConnection && walletConnection.address !== "" && walletConnection.connect === "yes" ?
                            <button
                                className="primary_btn"
                                disabled={isDisable6}
                                onClick={() => updateChildVal()}
                            >Update{isDisable6 && <i className="fas fa-spinner fa-spin"></i>}
                            </button>
                            :
                            <button
                                className="primary_btn"
                                data-bs-toggle="modal"
                                data-bs-target="#connect_wallet_modal"
                            >Update
                            </button>
                        }
                    </div>
                </div>
            </div>

            <div className="row" >
                <div className="col-lg-6 text-start mx-auto" >
                    <label className="mb-2" >Stop(Contract)</label>
                    <div class="input-group mt-0 mb-3 w-100">
                        <Switch onChange={handleChange} checked={ispause} />
                        {ispause && <p className="m-0 ms-2" >On</p>}
                        {!ispause && <p className="m-0 ms-2" >Off</p>}
                    </div>
                    <div className="d-flex mb-3" >
                        {walletConnection && walletConnection.address !== "" && walletConnection.connect === "yes" ?
                            <button
                                className="primary_btn"
                                disabled={isDisable3}
                                onClick={() => UpdatePause()}
                            >Update{isDisable4 && <i className="fas fa-spinner fa-spin"></i>}
                            </button>
                            :
                            <button
                                className="primary_btn"
                                data-bs-toggle="modal"
                                data-bs-target="#connect_wallet_modal"
                            >Update
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
