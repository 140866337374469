import React from "react";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";

import "react-phone-input-2/lib/style.css";

import ConnectWallet from "../../components/ConnectWallet";

import AutoPoolV1Settings from "./autoPoolV1Settings.js";
import AutoPoolV2Settings from "./autoPoolV2Settings.js";
import DopamineSetting from "./DopamineSetting.js";

export default function Settings(props) {
  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6 mx-auto">
              <AutoPoolV1Settings />
            </div>
            <div className="col-lg-6 col-xl-6 mx-auto">
              <AutoPoolV2Settings />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xl-6 mx-auto">
              <DopamineSetting />
            </div>
            {/* <div className="col-lg-6 col-xl-6 mx-auto">
              <AutoPoolV2Settings />
            </div> */}
          </div>
        </div>
      </div>
      <ConnectWallet />
      <Footer />
    </div>
  );
}
