import React, { useState } from "react";

import config from "../config/config";

import { Claimreward } from "../pages/Dashboard/dashboardinfo";
import { toastAlert } from "../helper/toastAlert";

export default function ClaimModal(props) {

    var { reload } = props;

    const [isDisable, setisDisable] = useState(false);
    const [txid, settxid] = useState("");


    async function claim() {

        setisDisable(true);
        var { isclaim, error, hash } = await Claimreward();
        if (error && error !== "") {
            toastAlert("error", error, 'claim');
        } else if (isclaim && hash) {
            settxid(hash);
            toastAlert("success", "Successfully Claimed");
            window.$("#claim_modal").modal('hide');
            window.$("#claim_succes_modal").modal('show');
            reload();
        } else {
            toastAlert("error", "Oops something went wrong", 'claim');
        }
        setisDisable(false)
    }

    return (
        <>
            <div
                className="modal fade primary_modal success"
                id="claim_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Confirm Claim
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="box border-0">
                                    <button
                                        className="primary_btn"
                                        onClick={() => claim()}
                                        disabled={isDisable}
                                    >Claim</button>
                                    {isDisable && <i className="fas fa-spinner fa-spin"></i>}

                                </div>
                                {isDisable && <span className="text-center">Please do not refresh the page, after confirm the payment.!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade primary_modal success"
                id="claim_succes_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Successfully Claimed
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="mb-4">
                                    <svg className="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                    <a rel="noreferrer" href={config.txLink + "/tx/" + txid} target="_blank" className="d-block mt-3 text-center"> <i className="fa fa-eye" aria-hidden="true">
                                    </i> View Transaction </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade primary_modal success"
                id="autopool_coming_soon"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                        <img src={require("../assets/images/auto-pool-coing-soon.png")} alt="img" className="img-fluid mx-auto" />
                            <button
                                type="button"
                                className="btn-close absolute-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
