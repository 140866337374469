import config from "../../config/config";
import { connection } from "../../helper/connection";
import { Multicall } from "ethereum-multicall";
import BEP20ABI from "../../ABI/BEP20.json";
import AUTOPOOLV3ABI from "../../ABI/AUTOPOOLV3.json"
import { getFormatMulticall1, shortText, formatNumber, getFormatMulticall } from "../../helper/custom";
import BigNumber from "bignumber.js"

function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
}

export async function getV3UserInfo() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontractv3,
                abi: AUTOPOOLV3ABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    }, {
                        reference: "viewUserReferal",
                        methodName: "viewUserReferal",
                        methodParameters: [address],
                    },

                ]
            }
        ]

        const results = await multicall.call(Contract);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        var referraldata = await getFormatMulticall1(results, "userinfo", 1);


        var userdata = {
            isExist: userinfo[0],
            id: bignumber(userinfo[1]),
            parent: userinfo[2],
            referrerID: bignumber(userinfo[3]),
            levelRewards: bignumber(userinfo[4]),
            levelRewardsClaimed: bignumber(userinfo[5]),
            intrestAmount: bignumber(userinfo[6]),
            stakeAmountClaimed: bignumber(userinfo[7]),
            totalWithdraw: bignumber(userinfo[8]),
            totalInvestment: bignumber(userinfo[9]),
            joinedDate: bignumber(userinfo[10]),
            referralcount: referraldata.length,
        }

        return userdata


    } catch (err) {
        console.log(err);
    }
}

export const getPoolInfo = async () => {
    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        var contractcall = new web3.eth.Contract(
            AUTOPOOLV3ABI,
            config.autoPoolcontractv3
        );
        const poolarr = []
        let Userreward = await contractcall.methods.userReward().call();

        let poollength = await contractcall.methods.getPoolLenght().call();
        for (let i = 0; i < poollength; i++) {
            let pooldata = await contractcall.methods.poolInfo(i).call();
            let Userpooldata = await contractcall.methods.userPoolInfo(address, i).call();

            poolarr.push({
                Poolid: i, min: pooldata?.min / 1e18 || 0, max: pooldata?.max / 1e18 || 0, intrest: pooldata?.intrest / 1e18 || 0,
                joinDate: Userpooldata?.joinDate,
                months: pooldata?.months || 0,
                status: pooldata?.status || 0,
                levelRewards: Userpooldata.levelRewards,
                levelRewardsClaimed: Userpooldata.levelRewardsClaimed,
                currentInvestment: Userpooldata.currentInvestment,
                lastCliameddate: Userpooldata.lastCliameddate,
                isExist: Userpooldata.isExist,
                stakeAmountClaimed: Userpooldata.stakeAmountClaimed,
                intrestAmount: Userpooldata.intrestAmount,
                monthlyPrincipleAmt: Userpooldata.monthlyPrincipleAmt,
                claimCount: Userpooldata.claimCount,
                Userreward: Userreward
            })
        }
        return poolarr

    } catch (err) {
        console.log(err);
    }
}


export const getHistoryData = async (pid) => {
    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        var contractcall = new web3.eth.Contract(
            AUTOPOOLV3ABI,
            config.autoPoolcontractv3
        );

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "userHistory",
                contractAddress: config.autoPoolcontractv3,
                abi: AUTOPOOLV3ABI,
                calls: [
                    {
                        reference: "getwithdrawHistory",
                        methodName: "getwithdrawHistory",
                        methodParameters: [address, pid],
                    }, {
                        reference: "getlevelWiseIncome",
                        methodName: "getlevelWiseIncome",
                        methodParameters: [address, pid],
                    }, {
                        reference: "viewUserInvestment",
                        methodName: "viewUserInvestment",
                        methodParameters: [address, pid],
                    },

                ]
            }
        ]

        const results = await multicall.call(Contract);
        var withdrawHistory = await getFormatMulticall1(results, "userHistory", 0);
        var levelWiseIncome = await getFormatMulticall1(results, "userHistory", 1);
        var UserInvestment = await getFormatMulticall1(results, "userHistory", 2);


        var levelWise = []
        var withHis = []
        var InvestHis = []

        // console.log(levelWiseIncome, "length");
        for (var i = 1; i <= 10; i++) {
            // console.log(i, "hhh");
            levelWise.push({ No: i, levelWiseIncome: bignumber(levelWiseIncome[i]) })
        }

        for (var i = 0; i < withdrawHistory.length; i++) {
            let withdate = await contractcall.methods.withdrawDate(address, pid, i).call();
            withHis.push({ No: i, withdrawHistory: bignumber(withdrawHistory[i]), date: withdate })
        }

        for (var i = 0; i < UserInvestment.length; i++) {

            let Invesdate = await contractcall.methods.viewUserInvestmentDate(address, pid).call();
            InvestHis.push({ No: i, UserInvestment: bignumber(UserInvestment[i]), date: Invesdate[i] })
        }


        return { levelWise: levelWise, withHis: withHis?.reverse(), InvestHis: InvestHis?.reverse() }


    } catch (err) {
        console.log(err);
    }
}


//new register
export const joinNow = async (referral, amount) => {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;


        const ContractToken = new web3.eth.Contract(
            BEP20ABI,
            config.tokenAddress
        );

        var contractcall = new web3.eth.Contract(
            AUTOPOOLV3ABI,
            config.autoPoolcontractv3
        );

        let firstPlan = await contractcall.methods.poolInfo("0").call();
        let planAmount = Number(firstPlan.poolAmount) / 1e18;

        const tokenBalance = await ContractToken.methods.balanceOf(address)
            .call();

        if (Number(amount) > Number(tokenBalance / 1e18)) {

            return {
                status: false,
                message: "Your USDT balance is low"
            };
        }

        let estimateGas = await ContractToken.methods
            .approve(config.autoPoolcontractv3, (amount * 1e18).toString())
            .estimateGas({ from: address })
            .catch((err) => console.log(err));

        let gasPriceUsdt = await web3.eth.getGasPrice();
        let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;
        var bnbBalance = await web3.eth.getBalance(address);

        if (estimateGasFee > bnbBalance) {

            return {
                status: false,
                message: "Your BNB balance is low"
            };
        }
        let referralcheck = await contractcall.methods.users(referral).call();

        let Usercheck = await contractcall.methods.users(address).call();


        if (Usercheck.isExist) {
            return {
                status: false,
                message: "User already exist"
            };
        }


        if (!referralcheck.isExist) {
            return {
                status: false,
                message: "Referral not exist"
            };
        }


        await ContractToken.methods.approve(config.autoPoolcontractv3, (amount * 1e18).toString()).send({ from: address }).then(async (res) => {

            await contractcall.methods.regUser(referral, (amount * 1e18).toString()).send({ from: address }).then(async (res) => { })
        })

        return {
            status: true,
            message: "Join Successfully"
        };

    } catch (err) {
        console.log(err);
        return {
            status: false,
            message: "Transaction Rejected"
        };
    }
}


export const ReJoinNow = async (pid, amount, QBtApprove) => {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;


        const ContractToken = new web3.eth.Contract(
            BEP20ABI,
            config.tokenAddress
        );

        var contractcall = new web3.eth.Contract(
            AUTOPOOLV3ABI,
            config.autoPoolcontractv3
        );

        let firstPlan = await contractcall.methods.poolInfo(pid).call();
        let planAmount = Number(firstPlan.poolAmount) / 1e18;

        const tokenBalance = await ContractToken.methods.balanceOf(address)
            .call();

        if (Number(amount) > Number(tokenBalance / 1e18)) {

            return {
                status: false,
                message: "Your USDT balance is low"
            };
        }

        let estimateGas = await ContractToken.methods
            .approve(config.autoPoolcontractv3, (amount * 1e18).toString())
            .estimateGas({ from: address })
            .catch((err) => console.log(err));

        let gasPriceUsdt = await web3.eth.getGasPrice();
        let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;
        var bnbBalance = await web3.eth.getBalance(address);

        if (estimateGasFee > bnbBalance) {
            return {
                status: false,
                message: "Your BNB balance is low"
            };
        }

        const ContractToken1 = new web3.eth.Contract(
            BEP20ABI,
            config.QbtAddress
        );

        const qbtBalance = await ContractToken1.methods.balanceOf(address)
            .call();

        if (QBtApprove) {



            if (Number(200) > Number(qbtBalance / 1e18)) {

                return {
                    status: false,
                    message: "Need 200 QBT to Claim"
                };
            }


            await ContractToken1.methods.approve(config.autoPoolcontractv3, (200 * 1e18).toString()).send({ from: address }).then(async (res) => {

            })
        }

        await ContractToken.methods.approve(config.autoPoolcontractv3, (amount * 1e18).toString()).send({ from: address }).then(async (res) => {

            await contractcall.methods.reJoinUser(pid, (amount * 1e18).toString()).send({ from: address }).then(async (res) => {

            })
        })


        return {
            status: true,
            message: "Join Successfully"
        };

    } catch (err) {
        console.log(err);
        return {
            status: false,
            message: "Transaction Rejected"
        };
    }

}

export const ClaimMonthly = async (pid) => {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        var contractcall = new web3.eth.Contract(
            AUTOPOOLV3ABI,
            config.autoPoolcontractv3
        );

        const ContractToken = new web3.eth.Contract(
            BEP20ABI,
            config.QbtAddress
        );

        const tokenBalance = await ContractToken.methods.balanceOf(address)
            .call();

        if (Number(200) > Number(tokenBalance / 1e18)) {

            return {
                status: false,
                message: "Need 200 QBT to Claim"
            };
        }


        await ContractToken.methods.approve(config.autoPoolcontractv3, (200 * 1e18).toString()).send({ from: address }).then(async (res) => {

            await contractcall.methods.cliamReward(pid).send({ from: address }).then(async (res) => {

            })
        })

        return {
            status: true,
            message: "Claimed Successfully"
        };


    } catch (err) {
        console.log(err);
        return {
            status: false,
            message: "Transaction Rejected"
        };
    }

}

export const LevelRewardclaim = async (pid) => {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        var contractcall = new web3.eth.Contract(
            AUTOPOOLV3ABI,
            config.autoPoolcontractv3
        );

        await contractcall.methods.cliamLevelReward(pid).send({ from: address }).then(async (res) => {

        })
        return {
            status: true,
            message: "Claimed Successfully"
        };

    } catch (err) {
        console.log(err);
        return {
            status: false,
            message: "Transaction Rejected"
        };
    }

}