import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toastAlert } from "../../helper/toastAlert";
import NFTABI from "../../ABI/AUTOPOOLV4.json";
import config from "../../config/config";

export default function DopamineSetting() {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [maxSupply, setMaxSupply] = useState(0);
  const [isDisable1, setisDisable1] = useState(false);
  const [isDisable2, setisDisable2] = useState(false);
  const [nftPrice, setNftPrice] = useState(0);

  useEffect(() => {
    getNFtdetails();
  }, [walletConnection.address]);

  const getNFtdetails = async () => {
    if (walletConnection.address) {
      let web3 = walletConnection.web3;
      let DesireContract = new web3.eth.Contract(NFTABI, config.contractV4);
      const nftPri = await DesireContract.methods._nftPrice().call();
      setNftPrice(Number(nftPri / 1e18));
      const nftCnt = await DesireContract.methods.maxSupply().call();
      setMaxSupply(Number(nftCnt));
    }
  };

  const UpdateMaxSup = async () => {
    if (walletConnection.address) {
      // if()
      setisDisable2(true);
      let web3 = walletConnection.web3;
      let DesireContract = new web3.eth.Contract(NFTABI, config.contractV4);
      let owneraddress = await DesireContract.methods.owner().call();
      if (
        owneraddress.toLowerCase() !== walletConnection.address.toLowerCase()
      ) {
        toastAlert("error", "Please connect owner wallet");
        setisDisable2(false);
        return false;
      }
      if (maxSupply == "" && maxSupply <= 0 && isNaN(maxSupply)) {
        toastAlert("error", "Invalid NFT Count field");
        setisDisable2(false);
        return false;
      }

      await DesireContract.methods
        .updateNftCount(maxSupply.toString())
        .send({ from: walletConnection.address })
        .then((res) => {
          if (res.status) {
            toastAlert("success", "Maximum Supply Updated");
            setisDisable2(false);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          setisDisable1(false);
        });
    } else {
      toastAlert("error", "Please connect wallet");
    }
  };
  const UpdatePriceNFT = async () => {
    if (walletConnection.address) {
      setisDisable1(true);
      let web3 = walletConnection.web3;
      let DesireContract = new web3.eth.Contract(NFTABI, config.contractV4);
      let owneraddress = await DesireContract.methods.owner().call();
      if (
        owneraddress.toLowerCase() !== walletConnection.address.toLowerCase()
      ) {
        toastAlert("error", "Please connect owner wallet");
        setisDisable1(false);
        return false;
      }
      if (nftPrice == "" && nftPrice <= 0 && isNaN(nftPrice)) {
        toastAlert("error", "Invalid price field");
        setisDisable1(false);
        return false;
      }
      await DesireContract.methods
        .updateNftPrice(nftPrice.toString())
        .send({ from: walletConnection.address })
        .then((res) => {
          if (res.status) {
            toastAlert("success", "NFT Price Updated");
            setisDisable1(false);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
          setisDisable1(false);
        });
    } else {
      toastAlert("error", "Please connect wallet");
    }
  };
  return (
    <div className="t_flx py-5 mt-3 d-block text-center box">
      <h6 className="mb-4 pb-2">Settings Dopamine NFT</h6>
      <div className="row">
        <div className="col-lg-6 text-start mx-auto">
          <label className="mb-2">Maximum Supply</label>
          <div class="input-group mt-0 mb-3 w-100">
            <input
              type="text"
              class="form-control"
              placeholder="Maximum Supply"
              aria-label="Maximum Supply"
              aria-describedby="basic-addon2"
              onChange={(e) => setMaxSupply(e.target.value)}
              value={maxSupply}
            />
          </div>
          <div className="d-flex mb-3">
            {walletConnection &&
            walletConnection.address !== "" &&
            walletConnection.connect === "yes" ? (
              <button
                className="primary_btn"
                disabled={isDisable2}
                onClick={() => UpdateMaxSup()}
              >
                Update{isDisable2 && <i className="fas fa-spinner fa-spin"></i>}
              </button>
            ) : (
              <button
                className="primary_btn"
                data-bs-toggle="modal"
                data-bs-target="#connect_wallet_modal"
              >
                Update
              </button>
            )}
          </div>

          <label className="mb-2">NFT Price</label>
          <div class="input-group mt-0 mb-3 w-100">
            <input
              type="text"
              class="form-control"
              placeholder="NFT Price"
              aria-label="NFT Price"
              aria-describedby="basic-addon2"
              onChange={(e) => setNftPrice(e.target.value)}
              value={nftPrice ? nftPrice : ""}
            />
          </div>
          <div className="d-flex mb-3">
            {walletConnection &&
            walletConnection.address !== "" &&
            walletConnection.connect === "yes" ? (
              <button
                className="primary_btn"
                disabled={isDisable1}
                onClick={() => UpdatePriceNFT()}
              >
                Update{isDisable1 && <i className="fas fa-spinner fa-spin"></i>}
              </button>
            ) : (
              <button
                className="primary_btn"
                data-bs-toggle="modal"
                data-bs-target="#connect_wallet_modal"
              >
                Update
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
