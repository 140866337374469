import React from "react";

const plus = (props) => (
  <svg  {...props} id="fi_10904516" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_2_00000183963590647485931270000000470916004425020071_">
      <g id="Layer_1_copy_3">
        <g id="_121" fill="#fff">
          <path d="m437 75c-101.4-98.5-263.5-96.1-362 5.3-96.5 99.3-96.5 257.4 0 356.7 101.4 98.5 263.5 96.1 362-5.3 96.5-99.3 96.5-257.4 0-356.7zm-181 396.8c-119 0-215.8-96.8-215.8-215.8s96.8-215.8 215.8-215.8 215.8 96.8 215.8 215.8-96.8 215.8-215.8 215.8z"></path><path d="m366.4 235.9h-90.3v-90.3c0-11.1-9-20.1-20.1-20.1s-20.1 9-20.1 20.1v90.3h-90.3c-11.1 0-20.1 9-20.1 20.1s9 20.1 20.1 20.1h90.3v90.3c0 11.1 9 20.1 20.1 20.1s20.1-9 20.1-20.1v-90.3h90.3c11.1 0 20.1-9 20.1-20.1s-9-20.1-20.1-20.1z"></path>
        </g>
      </g>
    </g>
  </svg>
);

const minus = (props) => (
  <svg {...props} height="512pt" fill="#fff" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg" id="fi_1828906">
    <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path><path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"></path>
  </svg>
);

const close = (props) => (
  <svg {...props} x="0px" y="0px" viewBox="0 0 416 416">
  </svg>
);

export { plus, minus, close };
