import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactLoading from "react-loading";

import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";

import {
  getAutoPoolInfo,
  getInitialautopool
} from "./autopoolinfo";

import AdvancePayModal from "../autopool/advancePayModal";
import RejoinModal from "../autopool/rejoinModal";
import ClaimModal from "../autopool/ClaimModal"
import JoinModal from "../autopool/joinModal";

import { getAutoPoolUsers } from "../../Api/Actions";

export default function Autopool() {

  const walletConnection = useSelector((state) => state.walletConnection);

  const [autoPoolInfo, setautoPoolInfo] = useState(getInitialautopool());
  const [isLoad, setisLoad] = useState(false);
  const [joinText, setjoinText] = useState("");
  const [userList, setuserList] = useState([]);
  const [skip, setskip] = useState(0);

  useEffect(() => {
    loadInitial();
  }, [walletConnection]);

  async function loadInitial() {
    setisLoad(true);
    if (walletConnection && walletConnection.address !== "") {
      var resp = await getAutoPoolInfo();
      setautoPoolInfo(resp);
      if (!resp.isJoined || resp.isJoined == false) {
        window.$("#join_modal").modal('show');
      }
      let data = {
        skip: 0
      }
      var { result } = await getAutoPoolUsers(data);

      setuserList(result)
    }
    setisLoad(false);
  }

  function reload1() {
    loadInitial();
  }


  function setText(val) {
    setjoinText();
    if (val == "join") {
      setjoinText("Join");
    } else {
      setjoinText("Re Join");
    }
  }

  const handleScroll = async (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      var skipVal = parseInt(skip) + 10;
      setskip(skipVal);
      let data = {
        skip: skipVal
      }
      var { result } = await getAutoPoolUsers(data);
      if (result && result.length > 0) {
        const combineList = userList.concat(result);
        setuserList(combineList);
      }
    }
  }


  return (
    <>
      <Navbar />
      <div className="dashboard">
        <div className="container">
          {/* <h6 className="mb-4 pb-2" >Referral</h6> */}
          <div className="head_tab mb-4">
            <Link to="/dashboard" className="primary_btn " >Referral Program</Link>
            <Link to="#" className="primary_btn  active">Autopool1.0</Link>
            <Link to="/autopoolv2k6byep90udetbaj321krlkie" className="primary_btn">Activate Autopool2.0</Link>
            <Link to="#" className="primary_btn"  data-bs-toggle="modal"
             data-bs-target="#autopool_coming_soon" >Activate Autopool3.0</Link>
            <Link to="/dopamine" className="primary_btn" 
            // data-bs-toggle="modal"
            //  data-bs-target="#dopamine_coming_soon" autopool_coming_soon
             >Dopamine</Link>
            {/* <Link to="#" className="primary_btn" data-bs-toggle="modal" data-bs-target="#gaming_coming_soon" >Game</Link> */}
          </div>

          <div className="row">
            <div className="col-lg-4 col-xl-3">
              <div className="box">
                <div>
                  <h5>{autoPoolInfo.id}</h5>
                  <p>ID</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d1.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{(autoPoolInfo.isComplete) ? "InActive" : "Active"}</h5>
                  <p className="text-start" >Status</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/activeuser.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{autoPoolInfo.parentId}</h5>
                  <p>Parent ID</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d1.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{autoPoolInfo.retopupcnt}</h5>
                  <p>Retopup count</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d2.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{autoPoolInfo.totalClaimed}</h5>
                  <p>Total Earned Amount</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/earnings.png")} alt="img" className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-xl-9">
              <div className="bdr mb-4">
                <div className="row top">
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p> Advance payment </p>
                      <h6>{autoPoolInfo.advancepayment} USDT</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p>Balance Amount</p>
                      <h6>{autoPoolInfo.balanceamount} USDT</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p>Used Amount</p>
                      <h6>{autoPoolInfo.usedamount} USDT</h6>
                    </div>
                  </div>
                </div>
                {isLoad && (
                  <div className="text-center dashboardloader">
                    <ReactLoading type={"bars"} color="#efcb46" className="loading" />
                  </div>
                )}
                <div className="box amount">
                  <div className="flx">
                    <p className="me-2 mt-0"> Withdraw Amount :</p>
                    <h6>{autoPoolInfo.earnedamount} USDT</h6>
                  </div>
                  <div className="flx gap-3">

                    <button
                      className="primary_btn m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#autopool_claim_modal"
                      disabled={(parseFloat(autoPoolInfo.earnedamount) > 0) ? false : true}
                    >Claim</button>

                    {/* <button
                      className="primary_btn m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#advancepay_modal"
                      disabled={autoPoolInfo.isJoined ? false : true}
                    >Topup</button> */}
                    {autoPoolInfo.isJoined ?
                      <button
                        className="primary_btn m-0"
                        data-bs-toggle="modal"
                        data-bs-target="#advancepay_modal"
                        disabled={autoPoolInfo.autoPoolReached ? false : true}
                      >Advance top-up</button>
                      :
                      <button
                        className="primary_btn m-0"
                        data-bs-toggle="modal"
                        data-bs-target="#join_modal"
                        onClick={() => setText('join')}
                      >Join</button>
                    }
                  </div>
                </div>
              </div>
              <div className="bdr mb-4" >
                <div class="scroll" onScroll={handleScroll}>
                  <h6 class="mb-3">User Details</h6>
                  <div class="table-responsive">
                    <table class="table table-borderless align-middle">
                      <thead class="table-light">
                        <tr>
                          <th>ID</th>
                          <th>Date. Of. Joining</th>
                          <th>Wallet Address</th>
                        </tr>
                      </thead>
                      <tbody class="table-group-divider">
                        {userList &&
                          userList.length > 0 &&
                          userList.map((item, index1) => {
                            return (<tr>
                              <td>{item.id}</td>
                              <td>{moment(item.createdAt).format("MMMM,Do YYYY")}</td>
                              <td>{item.address}</td>
                            </tr>);
                          })}
                      </tbody>
                    </table>
                    {userList && userList.length === 0 && <div className="text-center" >No records</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade primary_modal success"
            id="autopool_coming_soon"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <img src={require("../../assets/images/auto-pool-coing-soon.png")} alt="img" className="img-fluid mx-auto" />
                  <button
                    type="button"
                    className="btn-close absolute-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div
                className="modal fade primary_modal success"
                id="dopamine_coming_soon"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                        <img src={require("../../assets/images/dopamine-coing-soon.png")} alt="img" className="img-fluid mx-auto" />
                            <button
                                type="button"
                                className="btn-close absolute-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade primary_modal success"
                id="gaming_coming_soon"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                        <img src={require("../../assets/images/gaming-coing-soon.png")} alt="img" className="img-fluid mx-auto" />
                            <button
                                type="button"
                                className="btn-close absolute-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
          <AdvancePayModal
            reload={reload1}
            walletConnection={walletConnection}
          />
          <RejoinModal
            reload={reload1}
            joinText={joinText}
            walletConnection={walletConnection}
            joinAmount={autoPoolInfo.joinAmount}
          />
          <ClaimModal
            reload={reload1}
            walletConnection={walletConnection}
          />
          <JoinModal
            reload={reload1}
            walletConnection={walletConnection}
            joinAmount={autoPoolInfo.joinAmount}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
