import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar.js";
import Tree from "react-animated-tree-v2";
import config from "../../config/config.js";
import { toastAlert } from "../../helper/toastAlert.js";
import AUTOPOOLV4 from "../../ABI/AUTOPOOLV4.json";
import USDTABI from "../../ABI/BEP20.json";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";
import { Multicall } from "ethereum-multicall";
import BigNumber from "bignumber.js";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Dashboard_v1(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [nftPrice, setNftPrice] = useState(0);
  const [totInvest, setTotInvest] = useState("");
  const [totReward, setTotReward] = useState("");
  const [isDisable, setisDisable] = useState(false);
  const [DepositAmt, setDepostAmt] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [baseUri, setBaseUri] = useState("");
  const [nftData, setNftData] = useState([]);
  const [userInvest, setUserInvest] = useState(0);
  const [validation, setValidation] = useState("");
  const [nftHistory, setNftHistory] = useState([]);
  const [cliamAmo, setCliamAmo] = useState(0);
  const [loadingAmt, setLoadingAmt] = useState(false);

  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  }

  async function getFormatMulticall1(results, name, pos) {
    try {
      var returnVal =
        results &&
        results.results &&
        results.results[name] &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext &&
        results.results[name].callsReturnContext[pos] &&
        results.results[name].callsReturnContext[pos].returnValues &&
        results.results[name].callsReturnContext[pos].returnValues
          ? results.results[name].callsReturnContext[pos].returnValues
          : "";
      // console.log(returnVal, "returnVal");
      return returnVal;
    } catch (err) {
      return "";
    }
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log(accounts, "accountsef");
        // window.$("#join_modal").modal("hide");
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    getUserDetails();
    getNftData();
    getHistory();
  }, [walletConnection.address]);

  useEffect(() => {
    getClaimAmount();
  }, [walletConnection.address, userDetails]);

  const getNftData = async () => {
    if (walletConnection.address) {
      let web3 = walletConnection.web3;
      let nftArr = [];
      console.log(walletConnection, "wall");
      const DesireContract = new web3.eth.Contract(
        AUTOPOOLV4,
        config.contractV4
      );
      const nftIds = await DesireContract.methods
        .viewUserNft(walletConnection.address)
        .call();
      console.log(nftIds, "nftIds");
      const baseURI = await DesireContract.methods._baseTokenURI().call();
      console.log(baseURI, "baseURI");
      for (let ids of nftIds) {
        const response = await axios.get(`${baseURI + Number(ids)}.json`);
        console.log(response, "response");
        nftArr.push({
          id: ids,
          nftName: `Dopey Lotus Universe ${ids}`,
          nft: response.data
        });
      }
      setNftData(nftArr);
    }
  };
  const getUserDetails = async () => {
    try {
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        console.log(walletConnection, "wall");
        const DesireContract = new web3.eth.Contract(
          AUTOPOOLV4,
          config.contractV4
        );
        const nftPri = await DesireContract.methods._nftPrice().call();
        console.log(nftPri, "nftPri");
        setNftPrice(Number(nftPri / 1e18));
        const Users = await DesireContract.methods
          .users(walletConnection.address)
          .call();
        console.log(Users, "Users");
        setUserDetails(Users);
        if (!Users.isExist) {
          window.$("#join_modal").modal("show");
        }

        const getUserInvest = await DesireContract.methods
          .viewUserInvestment(walletConnection.address)
          .call();
        const numbers = getUserInvest.map(Number);
        const investments = numbers.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        console.log(investments, "investments");
        setUserInvest(investments / 1e18);

        // claimDailyCheck
        // const claimDailyCheck = await DesireContract.methods
        //   .claimDailyCheck(walletConnection.address)
        //   .call();
        // console.log(claimDailyCheck, "claimDailyCheck");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getHistory = async () => {
    try {
      if (walletConnection.address) {
        console.log("Check");
        let web3 = walletConnection.web3;
        let historyArr = [];
        let historyInves = [];
        const DesireContract = new web3.eth.Contract(
          AUTOPOOLV4,
          config.contractV4
        );
        const cliamAmt = await DesireContract.methods
          .viewWithdraw(walletConnection.address)
          .call();
        const cliamDate = await DesireContract.methods
          .viewWithdrawdate(walletConnection.address)
          .call();
        console.log(cliamAmt, cliamDate, "wqwwww");
        for (let i = 0; i < cliamAmt.length && i < cliamDate.length; i++) {
          if (Number(cliamAmt[i]) / 1e18 > 0) {
            historyArr.push({
              // sno: i + 1,
              amount: Number(cliamAmt[i]) / 1e18,
              cliamDate: Number(cliamDate[i])
            });
          }
        }
        setNftHistory(historyArr?.reverse());

        const investAmt = await DesireContract.methods
          .viewUserInvestment(walletConnection.address)
          .call();
        const investDate = await DesireContract.methods
          .viewUserInvestmentdate(walletConnection.address)
          .call();
        for (let i = 0; i < investAmt.length && i < cliamDate.length; i++) {
          historyInves.push({
            sno: i + 1,
            amount: Number(investAmt[i]) / 1e18,
            cliamDate: Number(investDate[i])
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 10 Min
  // const getClaimAmount = async () => {
  //   if (walletConnection.address) {
  //     setLoadingAmt(true);
  //     const web3 = walletConnection.web3;
  //     const DesireContract = new web3.eth.Contract(
  //       AUTOPOOLV4,
  //       config.contractV4
  //     );
  //     // const currentTimestamp = Math.floor(Date.now() / 1000);
  //     // const currentDate =
  //     //   currentTimestamp - (currentTimestamp % (1 * 24 * 60 * 60));
  //     const currentTimestamp = Math.floor(Date.now() / 1000);
  //     const tenMinutesInterval = 10 * 60; // 10 minutes in seconds
  //     const currentRoundedTimestamp =
  //       Math.floor(currentTimestamp / tenMinutesInterval) * tenMinutesInterval;
  //     const currentDate = new Date(currentRoundedTimestamp * 1000); // Convert seconds to milliseconds

  //     const dailyTotalLastRecordedDay = await DesireContract.methods
  //       .dailyTotalLastRecordedDay()
  //       .call();
  //     const _totalSupply = await DesireContract.methods._totalSupply().call();
  //     let totalSupply = 0;
  //     console.log(dailyTotalLastRecordedDay, "dailyTotalLastRecordedDay");
  //     const multicall = new Multicall({
  //       web3Instance: web3
  //     });
  //     let ContractPull = [];
  //     let Contract = [];
  //     let Cont = [];
  //     let amount = 0;

  //     // for (
  //     //   let day =
  //     //     new Date(Number(dailyTotalLastRecordedDay) * 1000).getTime() +
  //     //     1 * 24 * 60 * 60 * 1000;
  //     //   new Date(day).getTime() <= new Date(currentDate * 1000).getTime();
  //     //   day = new Date(day).getTime() + 1 * 24 * 60 * 60 * 1000
  //     // ) {
  //     //   ContractPull.push({
  //     //     reference: "DailyToken1-" + day,
  //     //     contractAddress: config.contractV4,
  //     //     abi: AUTOPOOLV4,
  //     //     calls: [
  //     //       {
  //     //         reference: "dailyTotalToken",
  //     //         methodName: "dailyTotalToken",
  //     //         methodParameters: [(day / 1000).toString()]
  //     //       }
  //     //     ]
  //     //   });
  //     // }

  //     // // Execute multicalls for daily total token
  //     // const result1 = await multicall.call(ContractPull);
  //     // for (let i = 0; i < ContractPull.length; i++) {
  //     //   let dailyTok = await getFormatMulticall1(
  //     //     result1,
  //     //     ContractPull[i].reference,
  //     //     0
  //     //   );
  //     //   dailyTok = await bignumber(dailyTok[0]);
  //     //   console.log(dailyTok, "weeeeeeeeeee", ContractPull[i].reference);
  //     //   if (dailyTok > 0) {
  //     //     totalSupply = _totalSupply;
  //     //   }
  //     //   // else {
  //     //   //   totalSupply = dailyTok;
  //     //   // }
  //     // }

  //     const nftIds = await DesireContract.methods
  //       .viewUserNft(walletConnection.address)
  //       .call();
  //     // const differenceInDays = Math.floor(
  //     //   (currentDate - Number(userDetails.lasteUpdateDate)) / (24 * 60 * 60)
  //     // );
  //     console.log(currentTimestamp, "currentTimestamp", userDetails);
  //     const differenceInDays = Math.floor(
  //       (currentTimestamp - Number(userDetails.lasteUpdateDate)) / 600
  //     );
  //     console.log(differenceInDays, "differenceInDays");
  //     // if (nftIds.length == 0) {
  //     //   setLoadingAmt(false);
  //     // }
  //     var nextDayMilliseconds = Number(userDetails.lasteUpdateDate) * 1000;
  //     if (nftIds.length > 0) {
  //       // if (Number(userDetails.lasteUpdateDate) * 1000 < currentDate*1000)
  //       for (let i = 0; i < differenceInDays; i++) {
  //         console.log(nextDayMilliseconds, "nextDayMilliseconds333");
  //         Cont.push({
  //           reference: "DailyToken-" + i,
  //           contractAddress: config.contractV4,
  //           abi: AUTOPOOLV4,
  //           calls: [
  //             {
  //               reference: "dailyTotalToken",
  //               methodName: "dailyTotalToken",
  //               methodParameters: [
  //                 Math.floor(nextDayMilliseconds / 1000).toString()
  //               ]
  //             }
  //           ]
  //         });
  //         Contract.push({
  //           reference: "DailyValue-" + i,
  //           contractAddress: config.contractV4,
  //           abi: AUTOPOOLV4,
  //           calls: [
  //             {
  //               reference: "dailyTotalValue",
  //               methodName: "dailyTotalValue",
  //               methodParameters: [
  //                 Math.floor(nextDayMilliseconds / 1000).toString()
  //               ]
  //             }
  //           ]
  //         });
  //         console.log(nextDayMilliseconds / 1000, "nextDayMilliseconds / 1000");
  //         nextDayMilliseconds =
  //           Number(userDetails.lasteUpdateDate) * 1000 + 1 * (600 * 1000);
  //         userDetails.lasteUpdateDate = nextDayMilliseconds / 1000;
  //       }

  //       const results = await Promise.all([
  //         multicall.call(Cont),
  //         multicall.call(Contract)
  //       ]);

  //       for (let i = 0; i < differenceInDays; i++) {
  //         let dailyTok = await getFormatMulticall1(
  //           results[0],
  //           "DailyToken-" + i,
  //           0
  //         );
  //         dailyTok = await bignumber(dailyTok[0]);
  //         let dailyVlu = await getFormatMulticall1(
  //           results[1],
  //           "DailyValue-" + i,
  //           0
  //         );
  //         dailyVlu = await bignumber(dailyVlu[0]);
  //         console.log(dailyTok, "dailyTok");
  //         if (dailyTok == 0) {
  //           totalSupply = _totalSupply;
  //         } else {
  //           totalSupply = dailyTok;
  //         }
  //         // if (totalSupply == 0) {
  //         //   totalSupply = dailyTok;
  //         // }

  //         console.log(dailyVlu, "dailyVlu", totalSupply);
  //         if (dailyVlu > 0) {
  //           amount += (dailyVlu / totalSupply) * nftIds.length;
  //         }
  //       }

  //       setCliamAmo(amount / 1e18);
  //       setLoadingAmt(false);
  //     } else if (nftIds.length == 0) {
  //       setLoadingAmt(false);
  //     }
  //   }
  // };

  //1 Day
  const getClaimAmount = async () => {
    if (walletConnection.address) {
      setLoadingAmt(true);
      const web3 = walletConnection.web3;
      const DesireContract = new web3.eth.Contract(
        AUTOPOOLV4,
        config.contractV4
      );
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const currentDate =
        currentTimestamp - (currentTimestamp % (1 * 24 * 60 * 60));
      const dailyTotalLastRecordedDay = await DesireContract.methods
        .dailyTotalLastRecordedDay()
        .call();
      const _totalSupply = await DesireContract.methods._totalSupply().call();
      let totalSupply = 0;
      console.log(dailyTotalLastRecordedDay, "dailyTotalLastRecordedDay");
      const multicall = new Multicall({
        web3Instance: web3
      });
      let ContractPull = [];
      let Contract = [];
      let Cont = [];
      let amount = 0;

      const nftIds = await DesireContract.methods
        .viewUserNft(walletConnection.address)
        .call();
      const differenceInDays = Math.floor(
        (currentDate - Number(userDetails.lasteUpdateDate)) / (24 * 60 * 60)
      );
      console.log(differenceInDays, "differenceInDays");
      // if (nftIds.length == 0) {
      //   setLoadingAmt(false);
      // }
      var nextDayMilliseconds = Number(userDetails.lasteUpdateDate) * 1000;
      if (nftIds.length > 0) {
        // if (Number(userDetails.lasteUpdateDate) * 1000 < currentDate*1000)
        for (let i = 0; i < differenceInDays; i++) {
          Cont.push({
            reference: "DailyToken-" + i,
            contractAddress: config.contractV4,
            abi: AUTOPOOLV4,
            calls: [
              {
                reference: "dailyTotalToken",
                methodName: "dailyTotalToken",
                methodParameters: [
                  Math.floor(nextDayMilliseconds / 1000).toString()
                ]
              }
            ]
          });
          Contract.push({
            reference: "DailyValue-" + i,
            contractAddress: config.contractV4,
            abi: AUTOPOOLV4,
            calls: [
              {
                reference: "dailyTotalValue",
                methodName: "dailyTotalValue",
                methodParameters: [
                  Math.floor(nextDayMilliseconds / 1000).toString()
                ]
              }
            ]
          });
          console.log(nextDayMilliseconds / 1000, "nextDayMilliseconds / 1000");
          nextDayMilliseconds =
            Number(userDetails.lasteUpdateDate) * 1000 +
            1 * 24 * 60 * 60 * 1000;
          userDetails.lasteUpdateDate = nextDayMilliseconds / 1000;
        }

        const results = await Promise.all([
          multicall.call(Cont),
          multicall.call(Contract)
        ]);

        for (let i = 0; i < differenceInDays; i++) {
          let dailyTok = await getFormatMulticall1(
            results[0],
            "DailyToken-" + i,
            0
          );
          dailyTok = await bignumber(dailyTok[0]);
          let dailyVlu = await getFormatMulticall1(
            results[1],
            "DailyValue-" + i,
            0
          );
          dailyVlu = await bignumber(dailyVlu[0]);

          if (dailyTok == 0) {
            totalSupply = _totalSupply;
          } else {
            totalSupply = dailyTok;
          }
          // if (totalSupply == 0) {
          //   totalSupply = dailyTok;
          // }

          console.log(dailyVlu, "dailyVlu", totalSupply);
          if (dailyVlu > 0) {
            amount += (dailyVlu / totalSupply) * nftIds.length;
          }
        }

        setCliamAmo(amount / 1e18);
        setLoadingAmt(false);
      } else if (nftIds.length == 0) {
        setLoadingAmt(false);
      }
    }
  };

  const handleDeposit = async () => {
    try {
      setisDisable(true);
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        if (
          Number(DepositAmt) == "" ||
          Number(DepositAmt) <= 0 ||
          isNaN(DepositAmt)
        ) {
          setValidation("Amount field is required or not valid");
          setisDisable(false);
          return false;
        }
        setValidation("");

        if (Number(DepositAmt) % nftPrice !== 0) {
          setValidation(`Amount must be a multiple of ${nftPrice}`);
          setisDisable(false);
          return false;
        }
        setValidation("");

        const DesireContract = new web3.eth.Contract(
          AUTOPOOLV4,
          config.contractV4
        );
        const totalSupply = await DesireContract.methods._totalSupply().call();
        const maxSupp = await DesireContract.methods.maxSupply().call();
        console.log(maxSupp, "maxSupp");
        // console.log(
        //   Number(totalSupply),
        //   Number(DepositAmt),
        //   nftPrice,
        //   "qqqqqqqq"
        // );
        if (
          Number(totalSupply) + Number(DepositAmt) / nftPrice >
          Number(maxSupp)
        ) {
          // setValidation(
          //   `Number(maxSupp) NFTs allow to mint, already ${totalSupply} NFTs minted`
          // );  (Number(DepositAmt) / nftPrice)-((Number(totalSupply) + Number(DepositAmt) / nftPrice) - 100 )
          //, already ${totalSupply} NFTs minted, now you can able to mint only ${
          //   Number(DepositAmt) / nftPrice -
          //   (Number(totalSupply) +
          //     Number(DepositAmt) / nftPrice -
          //     Number(maxSupp))
          // }
          setValidation(`Maximum NFTs reached`);
          setisDisable(false);
          return false;
        }
        setValidation("");

        const USDTContact = new web3.eth.Contract(USDTABI, config.tokenAddress);
        const User = await DesireContract.methods
          .users(walletConnection.address)
          .call();
        // if (User.isExist) {
        //   setValidation("User already exist");
        //   setisDisable(false);
        //   return false;
        // }
        // setValidation("");
        const isViewUserExits = await DesireContract.methods
          .viewUserExits(walletConnection.address)
          .call();
        console.log(isViewUserExits, "isViewUserExits");
        if (!isViewUserExits) {
          setValidation("User not registered in 'Dezire Referral'");
          setisDisable(false);
          return false;
        }
        setValidation("");
        const Balance = await USDTContact.methods
          .balanceOf(walletConnection.address)
          .call();

        if (Balance / 1e18 < Number(DepositAmt)) {
          setValidation("USDT balance is low");
          setisDisable(false);
          return false;
        }
        setValidation("");
        let Amount = Number(DepositAmt) * 1e18;
        Amount = await convert(Amount);
        console.log(Amount, "Amount");
        await USDTContact.methods
          .approve(config.contractV4, Amount.toString())
          .send({ from: walletConnection.address })
          .then(async (resp) => {
            if (resp.status == true && resp.transactionHash) {
              await DesireContract.methods
                .mint(Amount.toString())
                .send({ from: walletConnection.address })
                .then((res) => {
                  if (res.status == true && res.transactionHash) {
                    // setisDisable(false);
                    toastAlert("success", "Deposit completed successfully");
                    setTimeout(() => {
                      window.location.reload();
                    }, 2500);
                  } else {
                    setisDisable(false);
                    toastAlert("error", "Mint failed, try again later");
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setisDisable(false);
                  toastAlert("error", "Transaction rejected by user");
                });
            }
          })
          .catch((err) => {
            console.log(err);
            setisDisable(false);
            toastAlert("error", "Transaction rejected by user");
          });
      } else {
        toastAlert("error", "Please connect wallet");
        setisDisable(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClaimReward = async () => {
    try {
      setisDisable(true);
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        const DesireContract = new web3.eth.Contract(
          AUTOPOOLV4,
          config.contractV4
        );
        const User = await DesireContract.methods
          .users(walletConnection.address)
          .call();
        if (!User.isExist) {
          toastAlert("error", "User already exist");
          setisDisable(false);
          return false;
        }

        await DesireContract.methods
          .claimDaily()
          .send({ from: walletConnection.address })
          .then((res) => {
            if (res.status == true && res.transactionHash) {
              // setisDisable(false);
              toastAlert("success", "Claimed Successfully");
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          })
          .catch((err) => {
            console.log(err);
            setisDisable(false);
            window.location.reload();
          });
      } else {
        toastAlert("error", "You already claimed your reward");
        setisDisable(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="container">
          {/* <h6 className="mb-4 pb-2" >Referral</h6> */}
          <div className="head_tab mb-4">
            <Link to="/dashboard" className="primary_btn">
              Referral Program
            </Link>
            <Link
              to="/autopooliskd728jsnak382msmakncvae"
              className="primary_btn"
            >
              Autopool1.0
            </Link>
            <Link
              to="/autopoolv2k6byep90udetbaj321krlkie"
              className="primary_btn"
            >
              Activate Autopool2.0
            </Link>
            <Link to="#" className="primary_btn" data-bs-toggle="modal"
             data-bs-target="#autopool_coming_soon">
              {" "}
              Autopool3.0
            </Link>
            <Link
              to="/dopamine"
              className="primary_btn active"
              // data-bs-toggle="modal"
              // data-bs-target="#dopamine_coming_soon"
            >
              Dopamine
            </Link>
            {/* {/* <Link to="#" className="primary_btn" data-bs-toggle="modal" data-bs-target="#gaming_coming_soon" >Game</Link> */}
            {/* <Link to="/autopool" className="primary_btn">Activate Autopool</Link> */}
          </div>
          <div className="nft-inverstment-details">
            <div className="nid-card">
              <img
                src={require("../../assets/images/total-inverstment.png")}
                alt="img"
                className="img-fluid"
              />
              <div className="nid-text">
                <h6>{userInvest ? userInvest : 0}</h6>
                <p>Total Investment</p>
              </div>
            </div>
            <div className="nid-card">
              <img
                src={require("../../assets/images/total-earnings.png")}
                alt="img"
                className="img-fluid"
              />
              <div className="nid-text">
                <h6>
                  {userDetails?.cliamAmount
                    ? (userDetails?.cliamAmount / 1e18).toFixed(2)
                    : 0}
                </h6>
                <p>Total Claimed</p>
              </div>
            </div>
            <div className="nid-card">
              <img
                src={require("../../assets/images/total-earnings.png")}
                alt="img"
                className="img-fluid"
              />
              <div className="nid-text">
                {loadingAmt ? (
                  "Loading..."
                ) : (
                  <h6>{cliamAmo ? cliamAmo.toFixed(2) : 0}</h6>
                )}

                <p>Total Earnings</p>
                {cliamAmo > 0 && (
                  <button className="primary_btn" onClick={handleClaimReward}>
                    Claim
                  </button>
                )}
              </div>
            </div>
            <button
              className="primary_btn px-5"
              data-bs-toggle="modal"
              data-bs-target="#join_modal"
            >
              Deposit
            </button>
            {/* {console.log(async () => await handleDisClaim(),'wwdasdfsadf')} */}

            {/* {cliamAmo > 0 && (
              <button
                className="primary_btn dsable"
                // disabled={async () => await handleDisClaim()}
                onClick={handleClaimReward}
              >
                Claim Reward
              </button>
            )} */}
          </div>
          <h6 class="mb-4">My NFT</h6>
          <div className="nft-image-box">
            <div className="row">
              {nftData.length > 0 ? (
                nftData.map((nft) => {
                  return (
                    <div className="col-lg-4">
                      <div className="img-thumb mb-4">
                        <div className="img-border">
                          <Link to={`/dopamine-detail/${nft.id}`}>
                            <img
                              src={
                                "https://ipfs.io/ipfs/" +
                                nft.nft.image.split(":")[1].replace(/^\/+/g, "")
                              }
                              // src={nft.nft}
                              alt="img"
                              className="img-fluid mx-auto"
                            />
                          </Link>
                        </div>
                        <div className="img-bottom">
                          <p>
                            <Link to={`/dopamine-detail/${nft.id}`}>
                              {nft.nftName}
                            </Link>
                          </p>
                          <Link to={`/dopamine-detail/${nft.id}`}>
                            <button className="primary_btn btn-sm">
                              Details
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-lg-4">No NFTs Yet</div>
              )}

              {/* <div className="col-lg-4">
                <div className="img-thumb">
                  <div className="img-border">
                    <a href="#">
                      <img
                        src={require("../../assets/images/nft-img-2.jpg")}
                        alt="img"
                        className="img-fluid mx-auto"
                      />
                    </a>
                  </div>
                  <div className="img-bottom">
                    <p>
                      <a href="#">DEZIRE NFT 2</a>
                    </p>
                    <button className="primary_btn btn-sm">Details</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="img-thumb">
                  <div className="img-border">
                    <a href="#">
                      <img
                        src={require("../../assets/images/nft-img-3.jpg")}
                        alt="img"
                        className="img-fluid mx-auto"
                      />
                    </a>
                  </div>
                  <div className="img-bottom">
                    <p>
                      <a href="#">DEZIRE NFT 3</a>
                    </p>
                    <button className="primary_btn btn-sm">Details</button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="bdr my-4 mb-5">
            <h6 className="mb-3">Earnings History</h6>
            <div className="table_box">
              <div class="table-responsive">
                <table class="table table-borderless align-middle">
                  <thead class="table-light">
                    <tr>
                      <th>S.No</th>
                      <th>Date</th>
                      <th>Claim Amount</th>
                      {/* <th>Purchase Date</th>
                      <th>Earned Amount</th> */}
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    {nftHistory.length > 0 &&
                      nftHistory.map((ele, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {moment(Number(ele.cliamDate) * 1000).format(
                                "ll"
                              )}
                            </td>
                            <td>{ele.amount.toFixed(2)} USDT</td>
                            {/* <td>30.01.2024</td>
                            <td>1500</td> */}
                          </tr>
                        );
                      })}

                    {/* <tr>
                      <td>NFT1</td>
                      <td>DEZIRE NFT 1</td>
                      <td>ID 12345</td>
                      <td>30.01.2024</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>NFT1</td>
                      <td>DEZIRE NFT 1</td>
                      <td>ID 12345</td>
                      <td>30.01.2024</td>
                      <td>1500</td>
                    </tr> */}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      {/* popup  dopamine */}
      <div
        className="modal fade primary_modal success"
        id="dopamine_coming_soon"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <img
                src={require("../../assets/images/dopamine-coing-soon.png")}
                alt="img"
                className="img-fluid mx-auto"
              />
              <button
                type="button"
                className="btn-close absolute-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
      {/* popup  gaming */}
      <div
        className="modal fade primary_modal success"
        id="gaming_coming_soon"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <img
                src={require("../../assets/images/gaming-coing-soon.png")}
                alt="img"
                className="img-fluid mx-auto"
              />
              <button
                type="button"
                className="btn-close absolute-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <>
        <div
          className="modal fade primary_modal success"
          id="join_modal"
          tabIndex={-1}
          aria-labelledby="connect_walletModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title text-center"
                  style={{ textAlign: "center" }}
                  id="connect_walletModalLabel"
                >
                  Confirm Deposit
                </h5>
                {userDetails?.isExist && (
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                )}
              </div>
              <div className="modal-body">
                <div>
                  <div className="text-center">
                    {/* <label>Deposit Amount</label> */}
                    <input
                      placeholder="Amount "
                      className="primary-input m-3"
                      name="amount"
                      onChange={(e) => setDepostAmt(e.target.value)}
                      onInput={validPositive}
                      value={DepositAmt}
                    />
                    <div
                      style={{
                        color: "#ff4141",
                        position: "relative",
                        top: "-12px"
                        // float: "right"
                      }}
                    >
                      {validation ? validation : ""}
                    </div>
                    <div className="mb-2">
                      (Note : Amount must be a multiple of{" "}
                      {nftPrice ? nftPrice : 0})
                    </div>
                    <button
                      className="primary_btn"
                      onClick={() => handleDeposit()}
                      disabled={isDisable ? true : false}
                    >
                      Confirm
                      {isDisable && <i className="fas fa-spinner fa-spin"></i>}
                    </button>
                  </div>
                  {isDisable && (
                    <span className="text-center d-block">
                      Please do not refresh the page, after confirm the
                      payment.!
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {isDisable && (
        <div className="loadBefore">
          <ScaleLoader
            color={"#b79c2e"}
            loading={isDisable}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
       <div
            className="modal fade primary_modal success"
            id="autopool_coming_soon"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <img
                    src={require("../../assets/images/auto-pool-coing-soon.png")}
                    alt="img"
                    className="img-fluid mx-auto"
                  />
                  <button
                    type="button"
                    className="btn-close absolute-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
    </div>

  );
}
