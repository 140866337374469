import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import {
  getUserInfo,
  getInitialuserInfo,
  getIdToAddress,
  getLeftRightCount
} from "./dashboardinfo";

import {
  getTreeImage,
  firstTree,
  secondTree,
  thirdTree,
} from "./treeinfo.js";

import {
  numberOnly
} from "../../helper/custom";

import {
  getLoginId
} from "../../Api/Actions"

import ClaimModal from "../../components/ClaimModal";
import TopupModal from "../../components/TopupModal";
import HistoryList from "./historyList";

export default function Dashboard(props) {
  const walletConnection = useSelector((state) => state.walletConnection);

  const [isLoad, setisLoad] = useState(false);
  const [dashInfo, setdashInfo] = useState(getInitialuserInfo());
  const [copytext, setcopytext] = React.useState(false);
  const [copytext1, setcopytext1] = React.useState(false);
  const [copytext2, setcopytext2] = React.useState(false);
  const [copytext3, setcopytext3] = React.useState(false);


  const [firstRow, setfirstRow] = React.useState([]);
  const [secondRow, setsecondRow] = React.useState([]);
  const [thirdRow, setthirdRow] = React.useState([]);
  const [istree, setistree] = React.useState(false);
  const [userdetail1, setuserdetail1] = useState([]);
  const [userdetail2, setuserdetail2] = useState([]);
  const [userdetail3, setuserdetail3] = useState([]);
  const [SearchId, setSearchId] = useState("");
  const [rootId, setrootId] = useState(0);
  const [isSearch, setisSearch] = React.useState(false);
  const [emailPhone, setemailPhone] = React.useState("");
  const [leftCount, setleftCount] = React.useState(0);
  const [rightCount, setrightCount] = React.useState(0);

  useEffect(() => {
    loadInitial();
  }, [walletConnection]);

  function loadInitial() {
    getDetails();
    loadTree("");
    getTreeCount();
  }
  async function getTreeCount() {
    if (walletConnection && walletConnection.address !== "") {
      let { leftCnt, rightCnt } = await getLeftRightCount();
      setleftCount(leftCnt);
      setrightCount(rightCnt);
    }
  }


  async function getDetails() {
    setisLoad(true);
    if (walletConnection && walletConnection.address !== "") {
      var resp = await getUserInfo();
      setrootId(resp.id);
      setdashInfo(resp);
      getId(walletConnection.address);
    }
    setisLoad(false);

  }

  async function getId() {
    var { result } = await getLoginId({ address: walletConnection.address });
    setemailPhone(result);
  }

  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }

  async function onCopy1() {
    setcopytext1(true);
    setTimeout(function () {
      setcopytext1(false);
    }, 1000);
  }
  async function onCopy2() {
    setcopytext2(true);
    setTimeout(function () {
      setcopytext2(false);
    }, 1000);
  }

  async function onCopy3() {
    setcopytext3(true);
    setTimeout(function () {
      setcopytext3(false);
    }, 1000);
  }

  async function loadTree(addr) {

    setistree(true);
    var isfirstloop = false;
    let { tree1, isfirst, user1 } = await firstTree(addr);
    isfirstloop = isfirst;
    setuserdetail1(user1);
    setfirstRow(tree1);

    var tree2 = [];
    var issecondloop = false;
    if (tree1 && tree1.length > 0) {
      var { tree2, issecond, user2 } = await secondTree(tree1, isfirstloop);
      issecondloop = issecond;
      setuserdetail2(user2);
      setsecondRow(tree2);
    }

    if (tree2 && tree2.length > 0) {
      var { tree3, isthird, user3 } = await thirdTree(tree2, issecondloop);
      if (tree3 && tree3.length > 0) {
        setuserdetail3(user3);
        setthirdRow(tree3, isthird);
      }
    }
    setistree(false);
  }

  async function searchTree() {
    setisSearch(true)
    setrootId(SearchId)
    var { userAddr } = await getIdToAddress(SearchId);
    loadTree(userAddr);
    setisSearch(false)
  }

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      searchTree();
    }
  };

  function reload1() {
    getDetails();
  }

  const onChangeId = async (event) => {
    let value = event.target.value;
    var status = numberOnly(value);
    if (status) {
      setSearchId(value)
    }

  };

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="container">
          {/* <h6 className="mb-4 pb-2" >Referral</h6> */}
          <div className="head_tab mb-4">
            <Link to="/dashboard" className="primary_btn active" >Referral Program</Link>
            <Link to="/autopooliskd728jsnak382msmakncvae" className="primary_btn">Autopool1.0</Link>
            <Link to="/autopoolv2k6byep90udetbaj321krlkie" className="primary_btn">Activate Autopool2.0</Link>
            <Link to="#" className="primary_btn" data-bs-toggle="modal"
             data-bs-target="#autopool_coming_soon">Activate Autopool3.0</Link>
            <Link to="/dopamine" className="primary_btn" 
            // data-bs-toggle="modal"
            //  data-bs-target="#dopamine_coming_soon"
             >Dopamine</Link>
            {/* <Link to="#" className="primary_btn" data-bs-toggle="modal" data-bs-target="#gaming_coming_soon" >Game</Link> */}
            {/* <Link to="/autopool" className="primary_btn">Activate Autopool</Link> */}
          </div>
          <div className="row">
            <div className="col-lg-4 col-xl-3">
              <div className="box">
                <div>
                  <h5>{dashInfo.id}</h5>
                  <p>My Present ID</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d1.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{dashInfo.referrerID}</h5>
                  <p>Parent ID</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d1.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{dashInfo.DirectCount}</h5>
                  <p>Direct referral count</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d2.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{dashInfo.pairCount}</h5>
                  <p> Current Pair Count</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d3.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{dashInfo.maxpairLimit}</h5>
                  <p> Max Pair Count</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d3.png")} alt="img" className="img-fluid" />
                </div>
              </div>

              <div className="box">
                <div>
                  <p> Left team count: {dashInfo.leftCount}</p>
                  <p> Right team count: {dashInfo.rightCount}</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d3.png")} alt="img" className="img-fluid" />
                </div>
              </div>

              <div className="box">
                <div className="w-100" >
                  <p className="sm mb-2" ><span className="ylw" >Left Affiliate </span>Link</p>
                  <div className="link">
                    <div className="half" >{dashInfo.referalshow + "&isjoin=left"}</div>
                    <div className="ms-3" >
                      <CopyToClipboard
                        text={dashInfo.referalLink + "&isjoin=left"}
                        onCopy={() => onCopy()}
                      >
                        {!copytext ?
                          <img src={require("../../assets/images/copy.png")} alt="copy" className="img-fluid copy" />
                          :
                          <svg className="svgtick" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="8" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="white" strokeWidth="8" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                          </svg>
                        }
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="w-100" >
                  <p className="sm mb-2" ><span className="ylw" >Right Affiliate </span>Link</p>
                  <div className="link">
                    <div className="half" >{dashInfo.referalshow + "&isjoin=right"}</div>
                    <div className="ms-3" >
                      <CopyToClipboard
                        text={dashInfo.referalLink + "&isjoin=right"}
                        onCopy={() => onCopy2()}
                      >
                        {!copytext2 ?
                          <img src={require("../../assets/images/copy.png")} alt="copy" className="img-fluid copy" />
                          :
                          <svg className="svgtick" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="8" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="white" strokeWidth="8" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                          </svg>
                        }
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box">
                <div className="w-100" >
                  <p className="sm mb-2" ><span className="ylw" >Email </span>ID/<span className="ylw" >Phone </span>Number</p>
                  <div className="link">
                    <div className="half" >{emailPhone}</div>
                    <div className="ms-3" >
                      <CopyToClipboard
                        text={emailPhone}
                        onCopy={() => onCopy3()}
                      >
                        {!copytext3 ?
                          <img src={require("../../assets/images/copy.png")} alt="copy" className="img-fluid copy" />
                          :
                          <svg className="svgtick" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="8" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="white" strokeWidth="8" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                          </svg>
                        }
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-8 col-xl-9">
              <div className="bdr mb-4">
                <div className="row top">
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p>Deposited Amount</p>
                      <h6>{dashInfo.investAmount} USDT</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p>Direct Referral Income</p>
                      <h6>{dashInfo.directrefincome} USDT</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p> Pair Income </p>
                      <h6>{dashInfo.pairincome} USDT</h6>
                    </div>
                  </div>
                </div>
                {isLoad && (
                  <div className="text-center dashboardloader">
                    <ReactLoading type={"bars"} color="#efcb46" className="loading" />
                  </div>
                )}
                <div className="box amount">
                  <div className="flx">
                    <p className="me-2 mt-0"> Total Earned Amount :</p>
                    <h6>{dashInfo.earnedIncome} USDT</h6>
                  </div>
                  <div className="flx gap-3">
                    <button
                      disabled={(dashInfo && parseFloat(dashInfo.earnedIncome)) ?
                        false : true}
                      className="primary_btn m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#claim_modal"
                    >Claim</button>
                    <button
                      // disabled={(dashInfo && dashInfo.isReach) ?
                      //   false : true}
                      className="primary_btn m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#topup_modal"
                    >Topup</button>
                  </div>
                </div>
              </div>

              <div className="bdr position-relative mb-4">
                <div className="t_flx mt-3" >
                  <h6>Genealogy Tree  {istree && <i className="fas fa-spinner fa-spin"></i>}</h6>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ID"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={onChangeId}
                      onKeyPress={handleKeyPress}
                      value={SearchId}
                    />
                    <span class="input-group-text searchIcon" id="basic-addon2" onClick={() => searchTree()}>
                      {!isSearch ? <i class="fa-solid fa-magnifying-glass"></i> :
                        <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
                      }
                    </span>
                  </div>
                </div>
                <div className="flexcount">
                  <div className="left-Count count-details">
                    Extreme Left count: <span>{leftCount}</span>
                  </div>
                  <div className="right-Count count-details">
                    Extreme Right count: <span>{rightCount}</span>
                  </div>
                </div>
                <div className="scroll" >
                  <div className='plan_pool'>
                    <div className="plan_pool_head">
                      <img
                        src={require("../../assets/images/user_1.png")}
                        className="img-fluid"
                        alt="Icon"
                        id={"user_one"}
                      />
                      <ReactTooltip
                        anchorId={"user_one"}
                        place="bottom"
                        content={
                          <p className="tooltip_data text-center">
                            Id {rootId}
                          </p>
                        }
                      />
                    </div>

                    {/* First Row */}
                    <div className="plan_pool_first_row">
                      {firstRow &&
                        firstRow.length > 0 &&
                        firstRow.map((item1, index1) => {
                          var logo = getTreeImage(item1);
                          var index = userdetail1.findIndex(
                            (val) =>
                              val.address &&
                              val.address.toLowerCase() ===
                              item1.toLowerCase()
                          );
                          var userid = 0;
                          if (index !== -1) {
                            userid = userdetail1[index].id;
                          }
                          return (
                            <div
                              id={item1}
                              className="plan_pool_head_child"
                              data-tip
                              data-for={item1}
                            >

                              <img
                                src={require(`../../assets/images/${logo}`)}
                                id={"user_two" + item1}
                                className="img-fluid"
                                alt="Icon"
                              />
                              <ReactTooltip
                                anchorId={"user_two" + item1}
                                place="bottom"
                                content={
                                  <p className="tooltip_data text-center">
                                    Id {userid}
                                  </p>
                                }
                              />
                            </div>
                          );
                        })}
                    </div>

                    {/* Second Row */}
                    <div className="plan_pool_second_row">
                      {secondRow &&
                        secondRow.length > 0 &&
                        secondRow.map((item2, index2) => {
                          return (
                            <div>
                              {item2 &&
                                item2.length > 0 &&
                                item2.map((subitem1, subindex1) => {
                                  var logo = getTreeImage(subitem1);
                                  var index = userdetail2.findIndex(
                                    (val) =>
                                      val.address &&
                                      val.address.toLowerCase() ===
                                      subitem1.toLowerCase()
                                  );
                                  var userid = 0;
                                  if (index !== -1) {
                                    userid = userdetail2[index].id;
                                  }
                                  return (
                                    <div
                                      id={subitem1}
                                      className="plan_pool_head_child"
                                      data-tip
                                      data-for={subitem1}
                                    >
                                      <img
                                        src={require(`../../assets/images/${logo}`)}
                                        id={
                                          "user_three" + index2 + subindex1
                                        }
                                        className="img-fluid"
                                        alt="Icon"
                                      />
                                      <ReactTooltip
                                        anchorId={
                                          "user_three" + index2 + subindex1
                                        }
                                        place="bottom"
                                        content={
                                          <p className="tooltip_data text-center">
                                            Id {userid}
                                          </p>
                                        }
                                      />
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                    </div>

                    {/* third Row */}
                    <div className="plan_pool_third_row">
                      {thirdRow &&
                        thirdRow.length > 0 &&
                        thirdRow.map((item3, index3) => {

                          return (
                            <div>
                              {item3 &&
                                item3.length > 0 &&
                                item3.map((item4, index4) => {

                                  var logo1 = getTreeImage(
                                    item4
                                  );
                                  var index = userdetail3.findIndex(
                                    (val) =>
                                      val.address &&
                                      val.address.toLowerCase() ===
                                      item4.toLowerCase()
                                  );
                                  var userid = 0;
                                  if (index !== -1) {
                                    userid = userdetail3[index].id;
                                  }

                                  return (
                                    <div className="plan_pool_head_child"  >
                                      <img
                                        src={require(`../../assets/images/${logo1}`)}
                                        className="img-fluid"
                                        alt="Icon"
                                        id={
                                          "user_four" + index4 + item4
                                        }
                                      />
                                      <ReactTooltip
                                        anchorId={
                                          "user_four" + index4 + item4
                                        }
                                        place="bottom"
                                        content={
                                          <p className="tooltip_data text-center">
                                            Id {userid}
                                          </p>
                                        }
                                      />
                                    </div>
                                  );
                                })}

                            </div>
                          );
                        })}

                    </div>
                  </div>
                </div>
              </div>

              <HistoryList />

            </div>
          </div>

          {/* <Footer /> */}
        </div>
      </div>
      <div
                className="modal fade primary_modal success"
                id="dopamine_coming_soon"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                        <img src={require("../../assets/images/dopamine-coing-soon.png")} alt="img" className="img-fluid mx-auto" />
                            <button
                                type="button"
                                className="btn-close absolute-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade primary_modal success"
                id="gaming_coming_soon"
                tabIndex={-1}
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                        <img src={require("../../assets/images/gaming-coing-soon.png")} alt="img" className="img-fluid mx-auto" />
                            <button
                                type="button"
                                className="btn-close absolute-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
      <ClaimModal
        reload={reload1}
      />
      <TopupModal
        reload={reload1}
      />
      <Footer />
      <div
            className="modal fade primary_modal success"
            id="autopool_coming_soon"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <img
                    src={require("../../assets/images/auto-pool-coing-soon.png")}
                    alt="img"
                    className="img-fluid mx-auto"
                  />
                  <button
                    type="button"
                    className="btn-close absolute-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
    </div >
  );
}
