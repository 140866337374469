import { Multicall } from "ethereum-multicall";

import MLMABI from "../../ABI/MLMABI.json";
import config from "../../config/config";

import { isAddress, getFormatMulticall1, getFormatMulticall } from "../../helper/custom";
import { connection } from "../../helper/connection";

var zeroaddr = "0x0000000000000000000000000000000000000000";
var listEmp = [zeroaddr, zeroaddr];
var treeLimit = 2;

export async function firstTree(addr) {
    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        if (addr && addr !== "") {
            address = addr;
        }
        console.log(address, 'addressaddressmmmmm')
        const multicall = new Multicall({
            web3Instance: web3,
        });
        var Contract = [
            {
                reference: "viewUserReferral",
                contractAddress: config.contract,
                abi: MLMABI,
                calls: [
                    {
                        reference: "leftChild",
                        methodName: "leftChild",
                        methodParameters: [address],
                    },
                    {
                        reference: "rightChild",
                        methodName: "rightChild",
                        methodParameters: [address],
                    }

                ]
            }
        ];
        const results = await multicall.call(Contract);
        var leftChild = await getFormatMulticall1(results, "viewUserReferral", 0);
        var rightChild = await getFormatMulticall1(results, "viewUserReferral", 1);
        console.log(leftChild, 'leftChildleftChildleftChild', rightChild)

        var userList1 = [leftChild[0], rightChild[0]];
        var ref1List = [leftChild[0], rightChild[0]];
        var isfirst = false;
        if (leftChild !== zeroaddr || rightChild !== zeroaddr) {
            isfirst = true;//avoid loop next tree
        }

        var { userdetail } = await getuserInfos(userList1);
        console.log(ref1List, 'ref1Listref1List')
        console.log(userdetail, 'userdetailuserdetail', leftChild, rightChild)
        return {
            tree1: ref1List,
            isfirst: isfirst,
            user1: userdetail
        };
    } catch (err) {
        return {
            tree1: ref1List,
            isfirst: false,
            user1: []
        };
    }

}

export async function secondTree(list, isLoop) {
    var secondList = [listEmp, listEmp];

    try {
        if (isLoop) {
            var get = await connection();
            var web3 = get.web3;

            const multicall = new Multicall({
                web3Instance: web3,
            });
            var Contract = [];
            for (var r = 0; r < list.length; r++) {
                var addr = zeroaddr;
                if (isAddress(list[r])) {
                    addr = list[r];
                }

                Contract.push({
                    reference: "viewUserReferral-" + r,
                    contractAddress: config.contract,
                    abi: MLMABI,
                    calls: [
                        {
                            reference: "leftChild",
                            methodName: "leftChild",
                            methodParameters: [addr],
                        },
                        {
                            reference: "rightChild",
                            methodName: "rightChild",
                            methodParameters: [addr],
                        }

                    ]
                })

            }
            const results = await multicall.call(Contract);
            secondList = [];
            var refList = [];
            var userList2 = [];
            var issecond = false;
            for (var r1 = 0; r1 < list.length; r1++) {
                var addr1 = list[r1];
                if (addr1 !== zeroaddr) {
                    issecond = true;
                }
                var leftChild = await getFormatMulticall1(results, "viewUserReferral-" + r1, 0);
                var rightChild = await getFormatMulticall1(results, "viewUserReferral-" + r1, 1);
                refList = [leftChild[0], rightChild[0]];
                // userList2 = [leftChild[0], rightChild[0]];
                userList2.push(leftChild[0]);
                userList2.push(rightChild[0]);

                secondList.push(refList);
                refList = [];
            }
            console.log(userList2, 'userList2userList2userList2')
            var { userdetail } = await getuserInfos(userList2);

            return {
                tree2: secondList,
                issecond: issecond,
                user2: userdetail
            };
        } else {
            return {
                tree2: secondList,
                issecond: false,
                user2: []
            };
        }
    } catch (err) {
        return {
            tree2: secondList,
            issecond: false,
            user2: []
        };
    }

}

export async function thirdTree(list, isLoop) {

    var thirdList = [listEmp, listEmp, listEmp, listEmp];

    try {
        if (isLoop) {
            var get = await connection();
            var web3 = get.web3;

            const multicall = new Multicall({
                web3Instance: web3,
            });
            var Contract = [];
            for (var r = 0; r < list.length; r++) {

                for (var r1 = 0; r1 < list[r].length; r1++) {

                    var index = r + "-" + r1;

                    var addr = zeroaddr;
                    if (list[r] && list[r][r1] && isAddress(list[r][r1])) {
                        addr = list[r][r1];
                    }

                    Contract.push({
                        reference: "viewUserReferral-" + index,
                        contractAddress: config.contract,
                        abi: MLMABI,
                        calls: [
                            {
                                reference: "leftChild",
                                methodName: "leftChild",
                                methodParameters: [addr],
                            },
                            {
                                reference: "rightChild",
                                methodName: "rightChild",
                                methodParameters: [addr],
                            }


                        ]
                    });
                }

            }
            const results = await multicall.call(Contract);
            thirdList = [];
            var refList = [];
            var isthird = false;
            var userList3 = [];
            for (var r2 = 0; r2 < list.length; r2++) {
                //thirdList.push({ list: [] });
                for (var r3 = 0; r3 < list.length; r3++) {
                    var addr1 = (list[r2] && list[r2][r3]) ? list[r2][r3] : zeroaddr;
                    if (addr1 !== zeroaddr) {
                        isthird = true;
                    }
                    var index1 = r2 + "-" + r3;
                    var leftChild = await getFormatMulticall1(results, "viewUserReferral-" + index1, 0);
                    var rightChild = await getFormatMulticall1(results, "viewUserReferral-" + index1, 1);
                    refList = [leftChild[0], rightChild[0]];
                    // userList3 = [leftChild[0], rightChild[0]];

                    userList3.push(leftChild[0])
                    userList3.push(rightChild[0])

                    thirdList.push(refList);
                    refList = [];
                }

            }
            var { userdetail } = await getuserInfos(userList3);
            return {
                tree3: thirdList,
                isthird: isthird,
                user3: userdetail
            };
        } else {
            return {
                tree3: thirdList,
                isthird: false,
                userList3: [],
                user3: []
            };
        }
    } catch (err) {
        return {
            tree3: thirdList,
            isthird: false,
            userList3: [],
            user3: []
        };
    }


}


export async function getuserInfos(list) {

    try {
        if (list && list.length > 0) {
            var get = await connection();
            var web3 = get.web3;

            const multicall = new Multicall({
                web3Instance: web3,
            });
            var Contract = [];
            for (var r = 0; r < list.length; r++) {

                var addr = zeroaddr;
                if (isAddress(list[r])) {
                    addr = list[r];
                }

                Contract.push({
                    reference: "users-" + r,
                    contractAddress: config.contract,
                    abi: MLMABI,
                    calls: [
                        {
                            reference: "users",
                            methodName: "users",
                            methodParameters: [addr],
                        }

                    ]
                })

            }
            const results = await multicall.call(Contract);
            var userList = [];
            for (var r1 = 0; r1 < list.length; r1++) {

                var users = await getFormatMulticall1(results, "users-" + r1, 0);

                userList.push({
                    id: (users && users[1] && users[1].hex) ? parseInt(users[1].hex) : 0,
                    currentLevel: (users && users[3] && users[3].hex) ? parseInt(users[3].hex) : 0,
                    address: list[r1]
                })
            }

            return {
                userdetail: userList
            };
        } else {
            return {
                userdetail: []
            };
        }

    } catch (err) {
        return {
            userdetail: []
        };
    }

}

export function getTreeImage(addr) {
    var logo = (addr !== zeroaddr) ? "user_3.png" : "user_empty.png";
    return logo;

}