import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import Navbar from "../../components/Navbar.js";
import Tree from "react-animated-tree-v2";
import { plus, minus, close } from "../../components/icons.js";
import Treemodelv3 from "./Treev3.js";
import { getV3UserInfo, getPoolInfo } from "./autopoolinfov3.js"
import { convert } from "../../helper/convert";
import JoinModal from "./joinv3.js"

import ReJoinModal from "./rejoinv3.js"
import moment from "moment";
import { toastAlert } from "../../helper/toastAlert";
import { months } from "moment";

import { ClaimMonthly, LevelRewardclaim, getHistoryData } from "../autopoolv3/autopoolinfov3.js"
import { data } from "jquery";
import copy from "copy-to-clipboard";

import ScaleLoader from "react-spinners/ScaleLoader";
import config from "../../config/config.js"
import { useParams, useLocation } from "react-router-dom";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};
export default function Dashboard_v1(props) {
  const { id } = useParams();

  const walletConnection = useSelector((state) => state.walletConnection);

  const [activeButton, setActiveButton] = useState(0);
  const [autoPoolV3Info, setautoPoolv3Info] = useState();
  const [PoolInfo, setPoolInfo] = useState([]);
  const [crnPoolInfo, setcrnPoolInfo] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [ispopup, setispopup] = useState(true);
  const [history, sethistory] = useState([]);

  const [isrejoinpopup, setrejoinpopup] = useState(false);


  // const handleButtonClick = async (buttonId) => {
  //   setisLoad(true)

  //   setActiveButton(buttonId);
  //   var getdata = PoolInfo.find(id => id.Poolid == buttonId)
  //   setcrnPoolInfo(getdata)
  //   getHistoryData(getdata.Poolid)
  //   let history = await getHistoryData(getdata.Poolid)
  //   // console.log(history, "history");
  //   sethistory(history)
  //   setisLoad(false)
  //   // MonthEarning(1)
  // };


  useEffect(() => {
    loadInitial();
  }, [walletConnection.address, id]);


  const loadInitial = async () => {
    try {
      if (walletConnection && walletConnection.address !== "") {
        setisLoad(true)
        setActiveButton(0)
        if (id == undefined) {
          window.location.href = "/autopoolv3/0"

        }


        let userData = await getV3UserInfo()
        setautoPoolv3Info(userData)
        let poolinfo = await getPoolInfo()
        setPoolInfo(poolinfo)

        setcrnPoolInfo(poolinfo[id])
        setisLoad(false)
        setispopup(false)
        let history = await getHistoryData(poolinfo[id]?.Poolid)
        // console.log(history, "history");
        sethistory(history)
      }

    } catch (err) {
      console.log(err);
    }
  }


  const endDate = new Date(); // replace with your end date (or use a specific date)


  const allEarnedData = () => {
    try {

      let totalinv = Number(crnPoolInfo?.currentInvestment) + Number(crnPoolInfo?.monthlyPrincipleAmt) * Number(crnPoolInfo?.claimCount)
console.log(totalinv,"totalinv");


      let intrest = crnPoolInfo?.Userreward * crnPoolInfo?.intrest / 100
      let month = crnPoolInfo?.months
      let payoutPermonth = totalinv / Number(month)

      let earndata = 0

      earndata = earndata + Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest))
      for (let i = 1; i < Number(month); i++) {
        totalinv = totalinv - payoutPermonth
        earndata = earndata + Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest))
      }

      return ((earndata) || 0)

    } catch (err) {
      console.log(err);
    }
  }


  const MonthEarning = (count) => {
    try {
      let totalinv = (Number(crnPoolInfo?.currentInvestment) + Number(crnPoolInfo?.monthlyPrincipleAmt) * Number(crnPoolInfo?.claimCount))

      let intrest = crnPoolInfo?.Userreward * crnPoolInfo?.intrest / 100
      let month = crnPoolInfo?.months
      let payoutPermonth = totalinv / month
      let earndata = []
      //
      // console.log(month, 'month');

      earndata.push({ No: 1, value: Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest)) })
      for (let i = 1; i < month; i++) {
        // console.log(month, 'month');
        totalinv = totalinv - payoutPermonth
        earndata.push({ No: i + 1, value: Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest)) })

      }

      let PreEarning = 0
      for (let i = 0; i < Number(count); i++) {
        PreEarning = PreEarning + earndata[i]?.value || 0
      }

      return (PreEarning).toFixed(2)


      // return (findData.value || 0).toFixed(2)

    } catch (err) {

    }

  }

  const currentEarn = () => {
    try {
      let month = crnPoolInfo?.months
      let date2 = new Date(Number(crnPoolInfo?.lastCliameddate) * 1000)

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const timeDiff = Math.floor(endDate.getTime() - date2.getTime());
      let diffDays = Math.floor(timeDiff / oneDayInMilliseconds);

      // console.log(diffDays, "aaaa-");
      if (diffDays == 0) {
        diffDays = 1
      } else {
        let datecheck = diffDays / 30
        diffDays = Math.ceil(datecheck)

        if (Number(crnPoolInfo?.claimCount) + diffDays > month) {

          diffDays = month - Number(crnPoolInfo?.claimCount)
        }
      }

      let totalinv = (Number(crnPoolInfo?.currentInvestment) + Number(crnPoolInfo?.monthlyPrincipleAmt) * Number(crnPoolInfo?.claimCount))
      let intrest = crnPoolInfo?.Userreward * crnPoolInfo?.intrest / 100
      // let month = crnPoolInfo?.months
      let payoutPermonth = totalinv / month

      let earndata = []
      earndata.push({ No: 1, value: Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest)) })
      for (let i = 1; i < month; i++) {
        // console.log(month, 'month');
        totalinv = totalinv - payoutPermonth
        earndata.push({ No: i + 1, value: Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest)) })
      }

      let currentEarning = 0

      for (let i = Number(crnPoolInfo?.claimCount); i < Number(crnPoolInfo?.claimCount) + diffDays; i++) {
        currentEarning = currentEarning + earndata[i]?.value || 0
      }
      return (currentEarning).toFixed(2)

    } catch (err) {
      console.log(err);

    }

  }


  const claimFun = async (pid) => {
    try {
      setisLoad(true)

      const { status, message } = await ClaimMonthly(pid)
      if (!status) {
        toastAlert("error", message);
        setisLoad(false)
      } else {
        toastAlert("success", message);
        setisLoad(false)
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }

    } catch (err) {
      console.log(err);
    }

  }

  const claimlevelFun = async (pid) => {
    setisLoad(true)
    const { status, message } = await LevelRewardclaim(pid)
    if (!status) {
      toastAlert("error", message);
      setisLoad(false)
    } else {
      toastAlert("success", message);
      setisLoad(false)
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    }
  }

  function dateDiffInDays(date1, date2) {

    let month = crnPoolInfo?.months
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const timeDiff = Math.floor(date2.getTime() - date1.getTime());
    let diffDays = Math.floor(timeDiff / oneDayInMilliseconds);

    let datecheck = Math.ceil(diffDays / 30)



    // if (datecheck - 1 > 0) {
    //   return 0;
    // }

    if (diffDays == 0) {
      return 1;
    }
    else if (Number(crnPoolInfo?.claimCount) != month) {
      if (Number(crnPoolInfo?.claimCount) + datecheck > month) {
        return 0;
      }
    } if (Number(crnPoolInfo?.claimCount) == month) {
      return 1;

    }
    // console.log(diffDays % 30, "kkk");
    return diffDays % 30;

  }

  function reInversment(date1, date2) {
    let month = crnPoolInfo?.months

    const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(utcDate2 - utcDate1);

    // Convert the difference to days
    const diffDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    let datecheck = Math.ceil(diffDays / 30)


    // const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // const timeDiff = Math.floor(date2.getTime() - date1.getTime());
    // let diffDays = Math.ceil(timeDiff / oneDayInMilliseconds);
    // let datecheck = Math.ceil(diffDays / 30)
    // console.log(diffDays,datecheck,timeDiff,Math.ceil(29.7),"date");



    if (Number(crnPoolInfo?.claimCount) != month) {
      if (Number(crnPoolInfo?.claimCount) + datecheck > month) {
        return 0;
      }
    }
    if (Number(crnPoolInfo?.claimCount) == month) {
      return 1;
    }
    if (diffDays == 0) {
      return 0;
    }

    return diffDays % 30;

  }

  function monthCompleted() {
    try {
      let month = crnPoolInfo?.months

      let date1 = new Date(Number(crnPoolInfo?.lastCliameddate) * 1000)

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const timeDiff = Math.floor(endDate.getTime() - date1.getTime());
      let diffDays = Math.floor(timeDiff / oneDayInMilliseconds);
      let datecheck = Math.trunc(diffDays / 30)

      if (!crnPoolInfo?.isExist) {
        return 0
      }


      if (Number(crnPoolInfo?.claimCount) >= Number(month)) {
        return month
      }

      if (Number(datecheck) >= Number(month)) {
        return month
      }

      return datecheck + Number(crnPoolInfo?.claimCount)

    } catch (err) {
      console.log(err);

    }
  }

  const dateCalculation = () => {
    try {
      let month = crnPoolInfo?.months

      let date1 = new Date(Number(crnPoolInfo?.lastCliameddate) * 1000)

      const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const timeDiff = Math.floor(endDate.getTime() - date1.getTime());
      let diffDays = Math.floor(timeDiff / oneDayInMilliseconds);
      let datecheck = Math.ceil(diffDays / 30)


      if (!crnPoolInfo?.isExist) {
        return "-"
      }
      if (datecheck == 0) {
        datecheck = 1
      }
      if (Number(crnPoolInfo?.claimCount) == month) {
        return "-"
      }

      const dayofmonth = new Date(crnPoolInfo?.lastCliameddate * 1000).getTime() + (30 * datecheck) * 24 * 60 * 60 * 1000

      return (moment(Number(dayofmonth)).format("MMMM,Do YYYY")) || 0

    } catch (err) {
      console.log(err);

    }
  }

  const overAllEarning = (count) => {
    try {
      let month = crnPoolInfo?.months


      let totalinv = (Number(crnPoolInfo?.currentInvestment) + Number(crnPoolInfo?.monthlyPrincipleAmt) * Number(crnPoolInfo?.claimCount))
      let intrest = crnPoolInfo?.Userreward * crnPoolInfo?.intrest / 100
      // let month = crnPoolInfo?.months
      let payoutPermonth = totalinv / month

      let earndata = []
      earndata.push({ No: 1, value: Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest)) })
      for (let i = 1; i < month; i++) {
        totalinv = totalinv - payoutPermonth
        earndata.push({ No: i + 1, value: Number(payoutPermonth / 1e18) + ((Number(totalinv / 1e18) * intrest)) })
      }

      let currentEarning = 0

      for (let i = 0; i < Number(count); i++) {
        currentEarning = currentEarning + earndata[i]?.value || 0
      }
      return (Number(currentEarning))

    } catch (err) {
      console.log(err);
    }
  }



  async function copyToClipboard(clipdata) {
    copy(clipdata)
    toastAlert("success", "Copied Successfully");

  }


  return (
    <div>

      <Navbar />
      <div className="dashboard">
        <div className="container">
          {/* <h6 className="mb-4 pb-2" >Referral</h6> */}
          <div className="head_tab mb-4">
            <Link to="/dashboard" className="primary_btn">
              Referral Program
            </Link>
            <Link
              to="/autopooliskd728jsnak382msmakncvae"
              className="primary_btn"
            >
              Autopool1.0
            </Link>
            <Link
              to="/autopoolv2k6byep90udetbaj321krlkie"
              className="primary_btn"
            >
              Activate Autopool2.0
            </Link>
            <Link to="#" className="primary_btn active" data-bs-toggle="modal"
             data-bs-target="#autopool_coming_soon">
              {" "}
              Autopool3.0
            </Link>
            <Link
              to="/dopamine"
              className="primary_btn"
              // data-bs-toggle="modal"
              // data-bs-target="#dopamine_coming_soon"
            >
              Dopamine
            </Link>
            {/* {/* <Link to="#" className="primary_btn" data-bs-toggle="modal" data-bs-target="#gaming_coming_soon" >Game</Link> */}
            {/* <Link to="/autopool" className="primary_btn">Activate Autopool</Link> */}
          </div>

          {/* {isLoad && (
            <div className="text-center dashboardloader">
              <ReactLoading type={"bars"} color="#efcb46" className="loading" />
            </div>
          )} */}
          <div className="row">
            <div className="col-lg-12">
              <div className="grid_box top">
                <div className="box">
                  <p>Joining ID</p>
                  <h6>{autoPoolV3Info?.id || 0}</h6>
                </div>
                <div className="box">
                  <p>Total Investment</p>
                  <h6>{autoPoolV3Info?.totalInvestment / 1e18 || 0} USDT</h6>
                </div>
                <div className="box">
                  <p> Total Withdraw </p>
                  <h6>{(autoPoolV3Info?.totalWithdraw / 1e18 || 0).toFixed(4)} USDT</h6>
                </div>
                <div className="box">
                  <div>
                    <p> Total Level Reward</p>
                    <h6> {(autoPoolV3Info?.levelRewards / 1e18) || 0} USDT </h6>
                  </div>
                </div>
                <div className="box">
                  <div>
                    <p> Total Unstake Amount</p>
                    <h6> {(autoPoolV3Info?.stakeAmountClaimed / 1e18 || 0).toFixed(2)} USDT </h6>
                  </div>
                </div>
                <div className="box">
                  <div>
                    <p> Remaining Investment Amount</p>
                    <h6> {(Number(autoPoolV3Info?.totalInvestment / 1e18 || 0) - Number(autoPoolV3Info?.stakeAmountClaimed / 1e18 || 0)).toFixed(2)} USDT </h6>
                  </div>
                </div>
                <div className="box">
                  <div>
                    <p> Direct Referral Count</p>
                    <h6> {autoPoolV3Info?.referralcount || 0} </h6>
                  </div>
                </div>

                <div className="box">
                  <div>
                    <p>Next Claim On</p>
                    <h6> {dateCalculation() || 0} </h6>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-3 col-xl-3">
              <div className="affiliate_link_box">
                <div class="box">
                  <div class="inbox affiliate">
                    <div class="w-100">
                      <p class="mb-2">Referral Link</p>
                      <div class="link">
                        <div class="half">{`${config.frontUrl}/join-now/${walletConnection.address}`}</div>
                        <div class="ms-3">
                          {/* <i class="fa-solid fa-copy" ></i> */}
                          <p onClick={() => copyToClipboard(`${config.frontUrl}/join-now/${walletConnection.address}`)}>

                            <i class="fa-solid fa-copy" style={{
                              fontSize: "17px", cursor: "pointer", position: "relative",
                              // top: "9px"
                            }}></i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="nav flex-column nav-pills vertical_tab"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
               
                {PoolInfo && PoolInfo.map((item, index) => (

                  <a href={`/autopoolv3/${item.Poolid}`}

                    style={{ textDecoration: "none" }}
                    className={
                      id == item?.Poolid ? "active nav-link" : "nav-link"
                    }
                  >
                    <span class="text">Package {item?.Poolid + 1}</span>
                  </a>

                  // <button
                  //   className={
                  //     activeButton === item?.Poolid ? "active nav-link" : "nav-link"
                  //   }
                  //   onClick={() => handleButtonClick(item?.Poolid)}
                  // >
                  //   Package {item?.Poolid + 1}
                  // </button>
                )

                )}


              </div>
            </div>
            <div className="col-lg-9 col-xl-9">
              <div>
                <>
                  <div className="bdr mb-4">
                    <div className="joinnow_box">
                      <div>
                        <div>
                          <p>Min Investment</p>
                          <h6>{crnPoolInfo?.min || 0} USDT</h6>
                        </div>
                        <div>
                          <p> Max Investment</p>
                          <h6>{crnPoolInfo?.max || 0} USDT</h6>
                        </div>
                        <div>
                          <p>Interest Rate %</p>
                          <h6>{crnPoolInfo?.intrest || 0} %</h6>
                        </div>
                        <div>
                          <p>Investment Duration</p>
                          <h6>{crnPoolInfo?.months || 0} Month</h6>
                        </div>
                      </div>
                      {crnPoolInfo?.currentInvestment == 0 && crnPoolInfo?.isExist == true ?
                        <button class="primary_btn" onClick={() => setrejoinpopup(true)} >Re-Join</button> :
                        crnPoolInfo?.currentInvestment == 0 && crnPoolInfo?.isExist == false ?
                          <button class="primary_btn" onClick={() => setrejoinpopup(true)}>Join Now</button> : ""
                      }
                      <br></br>
                      {
                        crnPoolInfo?.currentInvestment != 0 && reInversment(new Date(Number(crnPoolInfo?.lastCliameddate) * 1000), endDate) == 0 ?
                          <button class="primary_btn" onClick={() => setrejoinpopup(true)} >Re-Invest</button> :
                          ""
                      }


                    </div>
                  </div>

                  <div className="bdr mb-4">
                    <div className="grid_box_large">
                      <div className="box">
                        <p>Previous Month Earnings</p>
                        {crnPoolInfo?.intrestAmount != 0 ?
                          <h6> {
                            MonthEarning(crnPoolInfo?.claimCount)

                            // (Number(crnPoolInfo?.monthlyPrincipleAmt / 1e18) +

                            //   (Number(crnPoolInfo?.currentInvestment / 1e18) + (Number(crnPoolInfo?.stakeAmountClaimed / 1e18)))
                            //   * Number(crnPoolInfo?.intrestAmount / 1e18))
                          } USDT</h6> : <h6> 0 USDT</h6>}
                      </div>


                      <div className="box">
                        <p>Current Earnings</p>
                        <h6>{currentEarn() || 0} USDT</h6>


                        {crnPoolInfo?.lastCliameddate != 0 &&
                          <button class="primary_btn mt-3 m-0"
                            style={
                              dateDiffInDays(new Date(Number(crnPoolInfo?.lastCliameddate) * 1000), endDate) == 0 ? {
                              } : { cursor: "not-allowed", opacity: "0.5" }}

                            disabled={
                              dateDiffInDays(new Date(Number(crnPoolInfo?.lastCliameddate) * 1000), endDate) == 0 ? false
                                : true
                            }
                            onClick={() => claimFun(crnPoolInfo?.Poolid)}>Claim</button>
                        }


                      </div>
                      <div className="box">
                        <div>
                          <p>Level Income Earnings</p>


                          <h6> {(((crnPoolInfo?.levelRewards / 1e18) - (crnPoolInfo?.levelRewardsClaimed / 1e18)) || 0).toFixed(2)} USDT</h6>
                          <button class="primary_btn mt-3 m-0"
                            onClick={() => claimlevelFun(crnPoolInfo?.Poolid)}

                            style={
                              (crnPoolInfo?.levelRewards / 1e18) - (crnPoolInfo?.levelRewardsClaimed / 1e18) == 0 ? { cursor: "not-allowed", opacity: "0.5" } : {}
                            }
                            disabled={
                              (crnPoolInfo?.levelRewards / 1e18) - (crnPoolInfo?.levelRewardsClaimed / 1e18) == 0 ? true : false
                            }
                          >Claim</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bdr mb-4">
                    <div className="box amount d-block">
                      <div className="flx">
                        <p className="me-2 mt-0 mb-2"> Remaining Earnings </p>
                      </div>
                      <div className="d-flex  gap-2">
                        <div className="w-100">
                          <div class="progress mt-1">

                            <div
                              class="progress-bar progress-bar-striped bg-warning"
                              role="progressbar"

                              style={{
                                width: `${Math.round(

                                  ((overAllEarning(monthCompleted()))
                                    /
                                    allEarnedData()

                                    * 100)

                                ) || 0}%`
                              }}


                              aria-valuenow="10"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>

                          <div className="text-end mon">
                            {
                              !ispopup &&
                              ` Earned amount 

                  
                              ${(overAllEarning(monthCompleted()) || 0).toFixed(2) || 0} 
                              
                              USDT/
                              
                              ${allEarnedData().toFixed(2) || 0} USDT
                              
                          
                              Month 
                              ${monthCompleted()}
                              
                                  /${crnPoolInfo?.months} completed`
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* Genealogy Tree */}
                  {walletConnection.address && !isLoad && autoPoolV3Info &&
                    <Treemodelv3 walletConnection={walletConnection}
                      userdata={autoPoolV3Info}

                    />}


                  <div className="bdr mb-4">
                    <h6 className="mb-3">History</h6>
                    <div className="table_box">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="one-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#one"
                            type="button"
                            role="tab"
                            aria-controls="one"
                            aria-selected="true"
                          >
                            Investment History
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="two-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#two"
                            type="button"
                            role="tab"
                            aria-controls="two"
                            aria-selected="false"
                          >
                            Withdraw History
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="three-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#three"
                            type="button"
                            role="tab"
                            aria-controls="three"
                            aria-selected="false"
                          >
                            Level History
                          </button>
                        </li>
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="one"
                          role="tabpanel"
                          aria-labelledby="one-tab"
                        >
                          <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                              <thead class="table-light">
                                <tr>
                                  <th>Date</th>
                                  <th>Address</th>
                                  <th>Total Amount (USDT)</th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">

                                {history && history?.InvestHis?.length > 0 && history?.InvestHis?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{moment((Number(item?.date)) * 1000).format("MMMM,Do YYYY hh:mm")}</td>
                                      <td>{walletConnection?.address}</td>
                                      <td> {item.UserInvestment / 1e18 || 0}</td>
                                    </tr>
                                  )

                                })
                                }

                              </tbody>
                              <tfoot></tfoot>
                            </table>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="two"
                          role="tabpanel"
                          aria-labelledby="two-tab"
                        >
                          <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                              <thead class="table-light">
                                <tr>
                                  <th>Date</th>
                                  {/* <th>Topup Amount (USDT)</th> */}
                                  <th>Address</th>
                                  <th>Total Amount (USDT)</th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">

                                {history && history?.withHis?.length > 0 && history?.withHis?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{moment((Number(item?.date)) * 1000).format("MMMM,Do YYYY hh:mm")}</td>
                                      <td>{walletConnection?.address}</td>
                                      <td> {item?.withdrawHistory / 1e18 || 0}</td>
                                    </tr>
                                  )

                                })
                                }

                              </tbody>
                              <tfoot></tfoot>
                            </table>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="three"
                          role="tabpanel"
                          aria-labelledby="three-tab"
                        >
                          <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                              <thead class="table-light">
                                <tr>

                                  <th>Level</th>
                                  <th>Earned Amount (USDT)</th>
                                </tr>
                              </thead>
                              <tbody class="table-group-divider">

                                {history && history?.levelWise?.length > 0 && history?.levelWise?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>Level {item?.No}</td>
                                      {/* <td>ID</td> */}
                                      <td> {item?.levelWiseIncome / 1e18 || 0}</td>
                                    </tr>
                                  )

                                })
                                }
                              </tbody>
                              <tfoot></tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>

              </div>
            </div>
          </div>

          {/* <Footer /> */}
        </div>
      </div>
      {/* popup  dopamine */}
      <div
        className="modal fade primary_modal success"
        id="dopamine_coming_soon"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <img
                src={require("../../assets/images/dopamine-coing-soon.png")}
                alt="img"
                className="img-fluid mx-auto"
              />
              <button
                type="button"
                className="btn-close absolute-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
      {/* popup  gaming */}
      <div
        className="modal fade primary_modal success"
        id="gaming_coming_soon"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <img
                src={require("../../assets/images/gaming-coing-soon.png")}
                alt="img"
                className="img-fluid mx-auto"
              />
              <button
                type="button"
                className="btn-close absolute-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>

      {
        !ispopup &&
        <JoinModal
          walletConnection={walletConnection}
          isExist={autoPoolV3Info?.isExist}
          pooldata={crnPoolInfo}
        />
      }
      <ReJoinModal
        claimdetail={monthCompleted()}
        walletConnection={walletConnection}
        isrejoinpopup={isrejoinpopup}
        setrejoinpopup={setrejoinpopup}
        pid={crnPoolInfo?.Poolid}
        pooldata={crnPoolInfo}
      />


      {
        isLoad && (
          <div className="loadBefore">
            <ScaleLoader
              color={"#b79c2e"}
              loading={isLoad}
              cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )
      }
      <div
            className="modal fade primary_modal success"
            id="autopool_coming_soon"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <img
                    src={require("../../assets/images/auto-pool-coing-soon.png")}
                    alt="img"
                    className="img-fluid mx-auto"
                  />
                  <button
                    type="button"
                    className="btn-close absolute-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
    </div >

  );
}
