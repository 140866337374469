import {
    WALLET_CONNECT,
    BALANCES
} from '../constant';

export const setWallet = details => {
    return {
        type: WALLET_CONNECT,
        payload: details
    };
};

export const setBalances = details => {
    return {
        type: BALANCES,
        payload: details
    };
};