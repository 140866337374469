import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

import config from "../config/config"

export default function Footer(props) {
    return (
        <div className={`main_footer`}>
            <div className="container">
                <div className={`footer_top`}>
                    <div className="row">
                        <div className="col-sm-12 col-lg-5">
                            <div className={`footerLogo mb-4 `}>
                                <img src={logo} alt="img" className="img-fluid" />

                            </div>
                            <div className={`footer_content`}>
                                <p className="mw-100  text-start">Ignite your high-earning trade</p>
                            </div>
                            <ul className={`social_links`}>
                                <li><a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                                    <i class="fa-brands fa-instagram"></i>
                                </a></li>
                                <li><a href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <i class="fa-brands fa-x-twitter"></i>
                                </a></li>
                                <li><a href="https://in.linkedin.com/" target="_blank" rel="noreferrer">
                                    <i class="fa-brands fa-linkedin-in"></i>
                                </a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4 col-lg-3">
                            <h5>Quick Links</h5>
                            <ul className="list-inline">
                                <li><a href={`${config.exchangeUrl}/spot/BTC_USDT`} target="_blank" rel="noreferrer">Trade Now</a></li>
                                <li><a href={`${config.exchangeUrl}/faq`} target="_blank" rel="noreferrer">FAQ</a></li>
                            </ul>
                        </div>
                        {/* <div className="col-sm-12 col-lg-4">
                            <h5>News for Updates</h5>
                            <p>Subscribe to get update and notify our exchange and products</p>
                            <div className={`signup sign_up rounded`}>
                                <div className={`input-group inputgrp`}>
                                    <input type="text" className="form-control" placeholder="Enter your email address" aria-label="Recipient's username" aria-describedby="basic-addon2" name="email"
                                      />
                                    <button className={`primary_btn input-group-text`} id="basic-addon2" >Send</button>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-sm-12 col-lg-4">
                            <h5>Contract address</h5>
                            <a href={config.txLink + "/address/" + config.contract} target="_blank" className="text-wrap">{config.contract}</a>
                        </div>
                    </div>
                </div>
                <div className={`footer_bottom`}>
                    <div className="col-12">
                        <p>Copyright © {new Date().getFullYear()} , Dezire Exchange, All rights reserved</p>
                        <p><a href={`${config.exchangeUrl}/terms`} target="_blank">Terms of service</a>
                            <span className="mx-1" >|</span>
                            <a href={`${config.exchangeUrl}/privacy-policy`} target="_blank">Privacy Policy</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
