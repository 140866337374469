import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";

import { toastAlert } from "../../helper/toastAlert";
// import { joinPool, getUserInfo } from "./autopoolinfo";
import { saveAutoPool } from "../../Api/Actions";
import Web3 from "web3";
import { ReJoinNow } from "../autopoolv3/autopoolinfov3"

import ScaleLoader from "react-spinners/ScaleLoader";

const override = {
    margin: "0 auto",
    borderColor: "red",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginLeft: "-32px",
    marginTop: "-32px",
    zIndex: "99999"
};
export default function ReJoinModal(props) {


    var { isrejoinpopup, setrejoinpopup, pid, claimdetail, pooldata } = props;

    const [amount, setamount] = useState("");
    const [isDisable, setisDisable] = useState(false);

    if (isrejoinpopup) {
        window.$("#Rejoin_modal").modal('show');
    } else {
        window.$("#Rejoin_modal").modal('hide');

    }

    const userRejoin = async () => {
        try {
            if (amount == "") {
                toastAlert("error", "Amount Required");
                return false
            }
            // console.log(amount >= pooldata.min, amount <= pooldata.max, "cc");

            if (!(amount >= pooldata.min && amount <= pooldata.max)) {
                toastAlert("error", `Amount Must be Minimum ${pooldata.min} USDT TO Maxmimum ${pooldata.max}USDT `);
                return false;

            }


            setisDisable(true)

            let QBtApprove = false
            if (pooldata?.currentInvestment != 0 &&claimdetail != pooldata.claimCount) {
                QBtApprove = true
            }

            const { status, message } = await ReJoinNow(pid, amount, QBtApprove)
            // console.log(status, message, "status, message");
            if (!status) {
                toastAlert("error", message);
                setisDisable(false)

            } else {
                toastAlert("success", message);
                setisDisable(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }

        } catch (err) {
            console.log(err);

        }
    }

    const handleClose = () => {

        setamount("")
        setrejoinpopup(false)
    }

    const validPositive = (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };

    return (
        <>
            <div
                className="modal fade primary_modal success"
                id="Rejoin_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Confirm Rejoin
                            </h5>

                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                                disabled={(isDisable) ? true : false}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div class="w-100 input_box text-center mb-2">
                                    {/* <span>Join amount {joinAmount} USDT</span> */}
                                </div>
                                <div className="text-center mt-3">
                                    <label>Amount-USDT </label>
                                    <input
                                        placeholder="Amount (USDT)"
                                        className="primary-input"
                                        name="amount"
                                        value={amount}
                                        onInput={validPositive}

                                        onChange={(e) => setamount(e.target.value)}
                                    />
                                    <button
                                        className="primary_btn"
                                        onClick={() => userRejoin()}
                                        disabled={(isDisable) ? true : false}
                                    >Confirm
                                        {isDisable && <i className="fas fa-spinner fa-spin"></i>}
                                    </button>

                                </div>
                                {isDisable && <span className="text-center d-block">Please do not refresh the page, after confirm the payment.!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isDisable && (
                    <div className="loadBefore">
                        <ScaleLoader
                            color={"#b79c2e"}
                            loading={isDisable}
                            cssOverride={override}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )
            }
        </>
    );


}

