import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config/config";
import { getTx } from "../../Api/Actions";
import { formatAddress } from "../../helper/custom";

import moment from "moment";

export default function HistoryList() {

    const walletConnection = useSelector((state) => state.walletConnection);
    const [list, setlist] = useState([]);

    useEffect(() => {
        loadInitial("topup");
    }, [walletConnection]);

    async function loadInitial(txtype) {
        if (walletConnection && walletConnection.address && walletConnection.address !== "") {
            setlist([]);
            let { result } = await getTx(walletConnection.address, txtype);
            setlist(result);

        }
    }

    return (
        <div className="bdr mb-4">
            <h6 className="mb-3" >History</h6>
            <div className="table_box">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation" onClick={() => loadInitial("topup")}>
                        <button class="nav-link active" id="one-tab" data-bs-toggle="tab" data-bs-target="#one" type="button" role="tab" aria-controls="one" aria-selected="true">Topup History</button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => loadInitial("referal")}>
                        <button class="nav-link" id="two-tab" data-bs-toggle="tab" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="false">Referral History</button>
                    </li>
                    <li class="nav-item" role="presentation" onClick={() => loadInitial("claim")}>
                        <button class="nav-link" id="three-tab" data-bs-toggle="tab" data-bs-target="#three" type="button" role="tab" aria-controls="three" aria-selected="false"> Claim History  </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                        <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                                <thead class="table-light">

                                    <tr>
                                        <th>Date</th>
                                        <th>Topup Amount (USDT)</th>
                                        <th>Address</th>
                                        <th>Hash</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    {list &&
                                        list.length > 0 &&
                                        list.map((item, index1) => {
                                            return (
                                                <tr>
                                                    <td>{moment(item.createdAt).format("MMMM,Do YYYY")}</td>
                                                    <td>{item.amount}</td>
                                                    <td id="titleshow" title={item.address}>{formatAddress(item.address)}</td>
                                                    <td><a rel="noreferrer" href={config.txLink + "/tx/" + item.hash} target="_blank"> <i className="fa fa-eye" aria-hidden="true"></i></a></td>
                                                </tr>
                                            );
                                        })}

                                </tbody>
                                <tfoot>

                                </tfoot>
                            </table>

                            {list && list.length === 0 && <div className="text-center" >No records</div>}
                        </div>
                    </div>
                    <div class="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                        <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount (USDT)</th>
                                        <th>Address</th>
                                        <th>Hash</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    {list &&
                                        list.length > 0 &&
                                        list.map((item, index1) => {
                                            return (
                                                <tr>
                                                    <td>{moment(item.createdAt).format("MMMM,Do YYYY")}</td>
                                                    <td>{item.amount}</td>
                                                    <td title={item.address}>{formatAddress(item.address)}</td>
                                                    <td><a href={config.txLink + "/tx/" + item.hash} target="_blank"><i className="fa fa-eye" aria-hidden="true"></i></a></td>
                                                </tr>
                                            );
                                        })}

                                </tbody>
                                <tfoot>

                                </tfoot>
                            </table>
                            {list && list.length === 0 && <div className="text-center" >No records</div>}
                        </div>
                    </div>
                    <div class="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                        <div class="table-responsive">
                            <table class="table table-borderless align-middle">
                                <thead class="table-light">
                                    <tr>
                                        <th>Date</th>
                                        <th>Claimed Amount (USDT)</th>
                                        <th>Address</th>
                                        <th>Hash</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider scrolldiv">
                                    {list &&
                                        list.length > 0 &&
                                        list.map((item, index1) => {
                                            return (
                                                <tr>
                                                    <td>{moment(item.createdAt).format("MMMM,Do YYYY")}</td>
                                                    <td>{item.amount}</td>
                                                    <td title={item.address}>{formatAddress(item.address)}</td>
                                                    <td><a href={config.txLink + "/tx/" + item.hash} target="_blank"><i className="fa fa-eye" aria-hidden="true"></i></a></td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                                <tfoot>

                                </tfoot>
                            </table>
                            {list && list.length === 0 && <div className="text-center" >No records</div>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
