import { Multicall } from "ethereum-multicall";
import MLMABI from "../../ABI/MLMABI.json";
import BEP20ABI from "../../ABI/BEP20.json";
import { saveTx } from "../../Api/Actions";
import config from "../../config/config";

import { getFormatMulticall1, shortText, formatNumber } from "../../helper/custom";
import { connection } from "../../helper/connection";
import { getAllowanceBalance } from "../../helper/common";
import { convert } from "../../helper/convert";

export function getInitialuserInfo() {

    return {
        id: 0,
        DirectCount: 0,
        pairCount: 0,
        directrefincome: 0,
        pairincome: 0,
        earnedIncome: 0,
        referalshow: "",
        referalLink: "",
        isReach: false,
        investAmount: 0,
        referral: "",
        referrerID: 0,
        maxpairLimit: 0,
        leftCount: 0,
        rightCount: 0
    }
}

export async function getUserInfo() {


    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var text = shortText(address);

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "userinfo",
                contractAddress: config.contract,
                abi: MLMABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    },
                    {
                        reference: "viewallDirectUserReferral",
                        methodName: "viewallDirectUserReferral",
                        methodParameters: [address],
                    },
                    {
                        reference: "userMaxpairLimit",
                        methodName: "userMaxpairLimit",
                        methodParameters: [address],
                    }

                ]
            }
        ];

        const results = await multicall.call(Contract);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        console.log(userinfo, 'userinfouserinfouserinfo')
        var directCnt = await getFormatMulticall1(results, "userinfo", 1);
        directCnt = (directCnt) ? directCnt.length : 0;
        var id = (userinfo && userinfo[1] && userinfo[1].hex) ? parseInt(userinfo[1].hex) : 0;
        var isReach = (userinfo && userinfo[2]) ? userinfo[2] : false;

        var pairCount = (userinfo && userinfo[11] && userinfo[11].hex) ? parseInt(userinfo[11].hex) : 0;

        var leftCount = (userinfo && userinfo[5] && userinfo[5].hex) ? parseInt(userinfo[5].hex) + pairCount : 0;
        var rightCount = (userinfo && userinfo[6] && userinfo[6].hex) ? parseInt(userinfo[6].hex) + pairCount : 0;

        var directrefincome = (userinfo && userinfo[12] && userinfo[12].hex) ? parseInt(userinfo[12].hex) : 0;
        directrefincome = formatNumber(directrefincome / 10 ** 18, 5);

        var pairincome = (userinfo && userinfo[9] && userinfo[9].hex) ? parseInt(userinfo[9].hex) : 0;
        pairincome = formatNumber(pairincome / 10 ** 18, 5);

        var earnedIncome = (userinfo && userinfo[10] && userinfo[10].hex) ? parseInt(userinfo[10].hex) : 0;
        earnedIncome = formatNumber(earnedIncome / 10 ** 18, 5);

        var investAmount = (userinfo && userinfo[13] && userinfo[13].hex) ? parseInt(userinfo[13].hex) : 0;
        investAmount = formatNumber(investAmount / 10 ** 18, 5);

        var referrerID = (userinfo && userinfo[7] && userinfo[7].hex) ? parseInt(userinfo[7].hex) : 0;

        var maxpairLimit = await getFormatMulticall1(results, "userinfo", 2);
        maxpairLimit = (maxpairLimit && maxpairLimit[0] && maxpairLimit[0].hex) ? parseInt(maxpairLimit[0].hex) : 0;

        return {
            id,
            DirectCount: directCnt,
            pairCount,
            directrefincome,
            pairincome,
            earnedIncome,
            referalshow: config.frontUrl + "/join-now?ref=" + text,
            referalLink: config.frontUrl + "/join-now?ref=" + address,
            isReach,
            investAmount,
            referral: address,
            referrerID,
            maxpairLimit,
            leftCount,
            rightCount
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        return getInitialuserInfo()
    }

}

export async function getLeftRightCount() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "getCount",
                contractAddress: config.contract,
                abi: MLMABI,
                calls: [
                    {
                        reference: "getExtremeLeftCount",
                        methodName: "getExtremeLeftCount",
                        methodParameters: [address],
                    },
                    {
                        reference: "getExtremeRightCount",
                        methodName: "getExtremeRightCount",
                        methodParameters: [address],
                    }

                ]
            }
        ];

        const results = await multicall.call(Contract);
        var leftCnt = await getFormatMulticall1(results, "getCount", 0);
        console.log(leftCnt, 'leftCntleftCntleftCnt')
        leftCnt = (leftCnt && leftCnt[0] && leftCnt[0].hex) ? parseInt(leftCnt[0].hex) : 0;

        var rightCnt = await getFormatMulticall1(results, "getCount", 1);
        rightCnt = (rightCnt && rightCnt[0] && rightCnt[0].hex) ? parseInt(rightCnt[0].hex) : 0;

        return {
            leftCnt,
            rightCnt
        }
    } catch (err) {
        console.log(err, 'errerrerr')
        return {
            leftCnt: 0,
            rightCnt: 0
        }
    }

}


export async function getIdToAddress(id) {

    try {
        var get = await connection();
        var web3 = get.web3;
        var Contract = new web3.eth.Contract(MLMABI, config.contract);
        var userAddr = await Contract.methods.userList(id).call();
        return {
            userAddr,
        }
    } catch (err) {
        return {
            userAddr: ""
        }
    }
}

export async function Claimreward() {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var balance = await web3.eth.getBalance(address);
        balance = balance / 10 ** 18;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(MLMABI, config.contract);

        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var user = await Contract.methods.users(address).call();
        var earnAmt = (user && user.earnedIncome) ? user.earnedIncome / 10 ** 18 : 0

        if (earnAmt == 0) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Insufficient reward`
            }
        }

        var estimateGas = await Contract.methods.claimRewards().estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.claimRewards().send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        var hash = (result && result.transactionHash) ? result.transactionHash : ""
        var data = {
            address: address,
            amount: earnAmt,
            txtype: "claim",
            hash
        }
        await saveTx(data)

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash,
            error: (result && result.status) ? "" : "Failed to claimed"
        }
    } catch (err) {
        console.log(err, 'errerrerrerr')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to claimed"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg
        }
    }
}


export async function Retopup(amount) {
    try {

        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var { balanceOf, allowance, bnbBal } = await getAllowanceBalance()

        var address = get.address;
        // var balance = await web3.eth.getBalance(address);
        var balance = bnbBal;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(MLMABI, config.contract);
        console.log(balance, 'balancebalance')
        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var Contract1 = [
            {
                reference: "userinfo",
                contractAddress: config.contract,
                abi: MLMABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    },
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "subscriptionFee",
                        methodName: "subscriptionFee",
                        methodParameters: [],
                    }

                ]
            }
        ];

        const results = await multicall.call(Contract1);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        var directParent = (userinfo && userinfo[8]) ? userinfo[8] : "";

        var isReach = (userinfo && userinfo[2]) ? userinfo[2] : false;

        // if (!isReach) {
        //     return {
        //         isclaim: false,
        //         hash: "",
        //         error: `Sorry, Unable to proceed re topup`
        //     }
        // }

        var joinAmount = await getFormatMulticall1(results, "userinfo", 1);
        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? parseInt(joinAmount[0].hex) : 0;

        var subscriptionFee = await getFormatMulticall1(results, "userinfo", 2);
        subscriptionFee = (subscriptionFee && subscriptionFee[0] && subscriptionFee[0].hex) ? parseInt(subscriptionFee[0].hex) : 0;

        var amt = joinAmount - subscriptionFee;

        var amt1 = amount;

        var amount = parseFloat(amount) * 10 ** 18;
        amount = amount.toString();
        amount = await convert(amount);




        if (parseFloat(balanceOf) < parseFloat(amt1)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have ${amt1} USDT in your wallet`
            }
        }
        console.log(allowance, amt1, 'amt1amt1amt1amt1')

        if (parseFloat(allowance) < parseFloat(amt1)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.contract,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.contract,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < amt1) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }
        console.log(amt, 'amtamtamtamtamt')

        var estimateGas = await Contract.methods.reTopUp(amount).estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }


        var result = await Contract.methods.reTopUp(amount).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        var topupcommision = await Contract.methods.referAmount().call();
        var hash = (result && result.transactionHash) ? result.transactionHash : ""
        var data = {
            address: address,
            amount: parseFloat(amount) / 10 ** 18,
            txtype: "topup",
            hash,
            topupcommision: parseFloat(topupcommision) / 10 ** 18,
            directParent: directParent
        }
        await saveTx(data)

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash: (result && result.transactionHash) ? result.transactionHash : "",
            error: (result && result.status) ? "" : "Failed to claimed"
        }
    } catch (err) {
        console.log(err, 'Retopup')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to topup"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg
        }
    }
}