import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  useWalletClient,
  useNetwork,
  useDisconnect,
} from "wagmi";
import Web3 from "web3";
import { setWallet, setBalances } from "../reducers/Actions";
import config from "../config/config";
import { walletClientToSigner } from "../helper/getprovider";
import { formatNumber, shortText } from "../helper/custom";
import { getAllowanceBalance } from "../helper/common";
import MLMABI from "../ABI/MLMABI.json";
import ConnectWallet from "../components/ConnectWallet";

export default function Navbar(props) {

  const dispatch = useDispatch();
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();
  const location = useLocation();
  const navigate = useNavigate();

  const walletConnection = useSelector((state) => state.walletConnection);
  const userBalances = useSelector((state) => state.balances);

  const { data: walletClient } = useWalletClient({ chainId: config.networkId });
  const [useraddress, setuseraddress] = useState("");
  const [isUser, setisUser] = useState(false);
  const [isLoad, setisLoad] = useState(false);

  useEffect(() => {
    setConnection();
    // eslint-disable-next-line
  }, [walletClient, chain]);

  async function setConnection() {
    console.log('setConnectionsetConnection')
    setisUser(false);
    setisLoad(false);
    if (walletClient && chain && parseInt(chain.id) === config.networkId) {
      var { transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);
      var userAddr =
        walletClient && walletClient.account && walletClient.account.address
          ? walletClient.account.address
          : "";
      var chainVal = chain && chain.id ? chain.id : config.networkId;
      dispatch(
        setWallet({
          network: parseInt(chainVal),
          web3: web3,
          address: userAddr,
          provider: transport,
          connect: "yes",
        })
      );
      setuseraddress(userAddr);

      var Contract = new web3.eth.Contract(MLMABI, config.contract);
      var userinfo = await Contract.methods.users(userAddr).call();
      if (userinfo && userinfo.isExist) {
        setisUser(true);
      } else {
        if (location.pathname === "/dashboard" || location.pathname === "/autopooliskd728jsnak382msmakncvae") {
          navigate("/join-now");
        }
      }

      setTimeout(async function () {
        try {
          var { balanceOf, allowance, bnbBal, Qbtbal } = await getAllowanceBalance();
          balanceOf = formatNumber(balanceOf);
          bnbBal = formatNumber(bnbBal);
          Qbtbal = formatNumber(Qbtbal);
          dispatch(
            setBalances({
              tokenbalance: balanceOf,
              tokenallowance: allowance,
              balance: bnbBal,
              isLoad: "yes",
              Qbtbalance: Qbtbal
            })
          );
        } catch (err) { }
      }, 500);
      setisLoad(true);
    } else {
      var chainVal1 =
        localStorage.getItem("network") &&
          parseInt(localStorage.getItem("network")) > 0
          ? parseInt(localStorage.getItem("network"))
          : config.networkId;
      var web3 = new Web3(config.rpcURl);

      if ((chain && parseInt(chain.id) !== config.networkId) || !chain) {
        if (location.pathname === "/dashboard" || location.pathname === "/autopooliskd728jsnak382msmakncvae") {
          navigate("/join-now");
        }
      }
      dispatch(
        setWallet({
          network: chainVal1,
          web3: web3,
          address: "",
          provider: "",
          connect: "no",
        })
      );
    }
  }

  function logout() {
    disconnect();
    localStorage.clear();
    navigate("/");
  }


  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="#">
            <img
              src={logo}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}

                <a href={`${config.exchangeUrl}`} target="_blank" className="ms-4" rel="noreferrer" >Home</a>
                <a href={`${config.exchangeUrl}/spot/BTC_USDT`} target="_blank" className="ms-4" rel="noreferrer"> Trade</a>
                <a href={`${config.exchangeUrl}/faq`} target="_blank" className="ms-4" rel="noreferrer">FAQ</a>
                <a href={`${config.exchangeUrl}/subscribe`} target="_blank" className="ms-4" rel="noreferrer">  Subscribe</a>
                <a href={`${config.exchangeUrl}/topup`} target="_blank" className="ms-4" rel="noreferrer">Topup</a>
                {walletConnection &&
                  walletConnection.address !== "" &&
                  walletConnection.connect === "yes" ?
                  <div className="dropdown ms-4">
                    <a
                      className="primary_btn dropdown-toggle address_btn"
                      href="#/"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={require("../assets/images/wallet.png")} alt="img" className="img-fluid me-2" />

                      <sapn className="mb-2" > {shortText(useraddress)} </sapn>
                    </a>
                    <ul
                      className="dropdown-menu walletdrop "
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#/">
                          <label>BNB Balance:</label>
                          <span className="ms-2">{userBalances && userBalances.balance
                            ? userBalances.balance
                            : 0}{" "} </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#/">
                          <label>USDT Balance:</label>
                          <span className="ms-2">{userBalances && userBalances.tokenbalance
                            ? userBalances.tokenbalance
                            : 0}{" "} </span>
                        </a>
                      </li>

                      <li>
                        <a className="dropdown-item" href="#/">
                          <label>QBT Balance:</label>
                          <span className="ms-2">{userBalances && userBalances.Qbtbalance
                            ? userBalances.Qbtbalance
                            : 0}{" "} </span>
                        </a>
                      </li>


                      {isUser && location.pathname !== "/dashboard" &&
                        <li>
                          <Link className="dropdown-item" to="/dashboard">
                            Dashboard
                          </Link>
                        </li>
                      }
                      <li onClick={() => logout()}>
                        <a className="dropdown-item" href="#/">
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                  :
                  <div
                    data-bs-dismiss="offcanvas"
                    aria-label="Close" >
                    <button
                      className="primary_btn ms-4"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      Connect Wallet
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <ConnectWallet />
    </>
  );
}
