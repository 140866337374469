import React, { useState } from "react";

import config from "../config/config";

import { Retopup } from "../pages/Dashboard/dashboardinfo";
import { toastAlert } from "../helper/toastAlert";

import { numberOnly } from "../helper/custom"

export default function TopupModal(props) {
    var { reload } = props;
    const [isDisable, setisDisable] = useState(false);
    const [txid, settxid] = useState("");
    const [amount, setamount] = useState(0);
    const [error, seterror] = useState("");

    async function topupVal() {

        var checkMultiply = parseFloat(amount);
        checkMultiply = checkMultiply % 15;

        if (parseFloat(checkMultiply) > 0 || parseFloat(amount) == 0) {
            seterror("Please enter multiply of 15");
            return;
        }

        setisDisable(true);

        var { isclaim, error, hash } = await Retopup(amount);
        if (error && error !== "") {
            toastAlert("error", error, 'claim');
        } else if (isclaim && hash) {
            settxid(hash);
            toastAlert("success", "Successfully topup");
            window.$("#topup_modal").modal('hide');
            window.$("#topup_succes_modal").modal('show');
            reload();
        } else {
            toastAlert("error", "Oops something went wrong", 'claim');
        }
        setisDisable(false)
    }

    const onChangeAmount = async event => {

        var value = event.target.value;
        var status = numberOnly(value);
        if (status) {

            var checkMultiply = parseFloat(value);
            checkMultiply = checkMultiply % 15;
            seterror("");
            if (parseFloat(checkMultiply) > 0) {
                seterror("Please enter multiply of 15");
            }
            setamount(value);
        } else if (value === "" || !status) {
            setamount("");
        }

    }

    return (
        <>
            <div
                className="modal fade primary_modal success"
                id="topup_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Confirm Topup
                            </h5>

                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div class="w-100 input_box mb-2">
                                    <label className="mb-1">Enter Topup Amount</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Amount"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        onChange={onChangeAmount}
                                        value={amount}
                                    />
                                    {error && error !== "" && <p style={{ color: 'red' }} className="m-0 text-start">{error}</p>}
                                </div>
                                <small>
                                    Please enter multiply of 15 . example: 15,30,45,60
                                </small>
                                <div className="box border-0 mt-3">
                                    <button
                                        className="primary_btn"
                                        onClick={() => topupVal()}
                                        disabled={(isDisable || error) ? true : false}
                                    >Confirm</button>
                                    {isDisable && <i className="fas fa-spinner fa-spin"></i>}

                                </div>
                                {isDisable && <span className="text-center d-block">Please do not refresh the page, after confirm the payment.!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="modal fade primary_modal success"
                id="topup_succes_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Successfully topup
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="mb-4">
                                    <svg className="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                    <a rel="noreferrer" href={config.txLink + "/tx/" + txid} target="_blank" className="d-block mt-3 text-center"> <i className="fa fa-eye" aria-hidden="true">
                                    </i> View Transaction </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
