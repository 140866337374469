import { Multicall } from "ethereum-multicall";
import { connection } from "../helper/connection";
import { getFormatMulticall } from "../helper/custom";

import config from "../config/config";

import MLMABI from "../ABI/MLMABI.json";
import BEP20ABI from "../ABI/BEP20.json";

const abiDecoder = require('abi-decoder');
abiDecoder.addABI(MLMABI);

export async function getAllowanceBalance() {

    try {
        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var address = get.address;
        var Contract = [
            {
                reference: "balanceOf",
                contractAddress: config.tokenAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    },
                    {
                        reference: "allowance",
                        methodName: "allowance",
                        methodParameters: [address, config.contract],
                    },
                ]
            },
            {
                reference: "balanceOf1",
                contractAddress: config.QbtAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    }
                ]
            }
        ];

        const results = await multicall.call(Contract);
        var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
        balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
        var allowance = await getFormatMulticall(results, "balanceOf", 1);
        allowance = parseInt(allowance.hex) / 10 ** 18;

        var getBalance = await web3.eth.getBalance(address);
        getBalance = parseFloat(getBalance);
        getBalance = getBalance / 10 ** 18;

        var Qbtbal = await getFormatMulticall(results, "balanceOf1", 0);
        Qbtbal = parseInt(Qbtbal.hex) / 10 ** 18;


        return {
            balanceOf,
            allowance,
            bnbBal: getBalance,
            Qbtbal
        }
    } catch (err) {
        console.log(err, 'getAllowanceBalance')
        return {
            balanceOf: 0,
            allowance: 0,
            bnbBal: getBalance,
            Qbtbal: 0
        }
    }

}

export async function getAllowanceValue(spender) {

    try {
        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var address = get.address;
        var Contract = [
            {
                reference: "balanceOf",
                contractAddress: config.tokenAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    },
                    {
                        reference: "allowance",
                        methodName: "allowance",
                        methodParameters: [address, spender],
                    },
                ]
            }];

        const results = await multicall.call(Contract);
        var balanceOf = await getFormatMulticall(results, "balanceOf", 0);
        balanceOf = parseInt(balanceOf.hex) / 10 ** 18;
        var allowance = await getFormatMulticall(results, "balanceOf", 1);
        allowance = parseInt(allowance.hex) / 10 ** 18;

        var getBalance = await web3.eth.getBalance(address);
        getBalance = parseFloat(getBalance);
        getBalance = getBalance / 10 ** 18;


        return {
            balanceOf,
            allowance,
            bnbBal: getBalance
        }
    } catch (err) {
        console.log(err, 'getAllowanceBalance')
        return {
            balanceOf: 0,
            allowance: 0,
            bnbBal: getBalance
        }
    }

}