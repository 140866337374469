import axios from "axios";

import config from "../config/config";

export const checkUsers = async (data) => {
    var url = config.serverUrl + "/check-users";
    try {
        let respData = await axios({
            'method': 'post',
            'url': url,
            data
        });
        return {
            loading: true,
            error: "",
            result: (respData && respData.data && respData.data.result) ? respData.data.result : {},
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
        }
    }
    catch (err) {
        console.log(err, 'errerrerr saveUsers')
        return {
            loading: true,
            status: false
        }
    }
}

export const saveUsers = async (data) => {
    var url = config.serverUrl + "/save-users";
    try {
        let respData = await axios({
            'method': 'post',
            'url': url,
            data
        });
        return {
            loading: true,
            error: "",
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
        }
    }
    catch (err) {
        console.log(err, 'errerrerr saveUsers')
        return {
            loading: true,
            status: false
        }
    }
}

export const saveTx = async (data) => {
    var url = config.serverUrl + "/save-transaction";

    try {
        let respData = await axios({
            'method': 'post',
            'url': url,
            data
        });
        return {
            loading: true,
            error: "",
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
        }
    }
    catch (err) {
        console.log(err, 'errerrerr saveUsers')
        return {
            loading: true,
            status: false
        }
    }
}

export const getTx = async (address, txtype) => {
    var url = config.serverUrl + "/get-transactions?address=" + address + "&txtype=" + txtype;
    try {
        let respData = await axios({
            'method': 'get',
            'url': url
        });
        return {
            loading: true,
            error: "",
            result: (respData && respData.data && respData.data && respData.data.result) ? respData.data.result : [],
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
        }
    }
    catch (err) {
        console.log(err, 'errerrerr saveUsers')
        return {
            loading: true,
            status: false
        }
    }
}

export const getLoginId = async (data) => {
    var url = config.serverUrl + "/get-user-info";

    try {
        let respData = await axios({
            'method': 'post',
            'url': url,
            data
        });
        return {
            loading: true,
            error: "",
            result: (respData && respData.data && respData.data.result) ? respData.data.result : "",
        }
    }
    catch (err) {
        console.log(err, 'errerrerr saveUsers')
        return {
            loading: true,
            status: false,
            result: ""
        }
    }
}

export const saveAutoPool = async (data) => {
    var url = config.serverUrl + "/save-pool";
    try {
        let respData = await axios({
            'method': 'post',
            'url': url,
            data
        });
        return {
            loading: true,
            error: "",
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
        }
    }
    catch (err) {
        console.log(err, 'errerrerr saveUsers')
        return {
            loading: true,
            status: false
        }
    }
}


export const getAutoPoolUsers = async (data) => {
    var url = config.serverUrl + "/get-autopool-users";
    try {
        let respData = await axios({
            'method': 'post',
            'url': url,
            'data': data
        });
        return {
            loading: true,
            error: "",
            result: (respData && respData.data && respData.data && respData.data.result) ? respData.data.result : [],
            status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
        }
    }
    catch (err) {
        console.log(err, 'errerrerr getAutoPoolUsers')
        return {
            loading: true,
            status: false
        }
    }
}