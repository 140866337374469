import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config/config";

import { toastAlert } from "../../helper/toastAlert";
// import { joinPool, getUserInfo } from "./autopoolinfo";
import { saveAutoPool } from "../../Api/Actions";
import Web3 from "web3";
import { joinNow } from "../autopoolv3/autopoolinfov3"
import { useParams, useLocation } from "react-router-dom";





export default function JoinModal(props) {

    const navigate = useNavigate();
    const { address } = useParams();

    var initialstate = {
        referral: "",
        amount: ""
    }

    var { isExist, walletConnection, pooldata } = props;
    const [isDisable, setisDisable] = useState(false);
    const [txid, settxid] = useState("");
    const [popupdate, setpopupdate] = useState(initialstate)

    const { referral, amount } = popupdate


    const HandleChange = async (e) => {
        try {
            const { name, value } = e.target
            let formData = { ...popupdate, ...{ [name]: value } };
            setpopupdate(formData);

            // console.log(popupdate);
        } catch (err) {
            console.log(err);

        }
    }

    const handleJoin = async () => {
        try {

            var web3 = new Web3(walletConnection.web3);

            if (referral == "") {
                toastAlert("error", "Referral Address Required");
                return false
            }
            if (amount == "") {
                toastAlert("error", "Amount Required");
                return false
            }
            if (!web3.utils.isAddress(referral)) {
                toastAlert("error", "Referral ID is not valid");
                return false;
            }

            if (!(amount >= pooldata.min && amount <= pooldata.max)) {
                toastAlert("error", `Amount Must be Minimum ${pooldata.min} USDT TO Maxmimum ${pooldata.max}USDT `);
                return false;

            }

            setisDisable(true)
            const { status, message } = await joinNow(referral, amount)
            // console.log(status, message, "status, message ");
            if (!status) {
                toastAlert("error", message);
                setisDisable(false)

            } else {
                toastAlert("success", message);
                setisDisable(false)
                setTimeout(() => {
                    window.location.href = "/autopoolv3/0"
                }, 2000);
            }

        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        popopen()
        let formData = { ...popupdate, ...{ referral: address == undefined ? "" : address, amount: "" } };
        setpopupdate(formData);
    }, [isExist, walletConnection])


    const popopen = async () => {

        // console.log(isExist, "isExist");

        if (isExist == false) {
            window.$("#join_modal").modal('show');
            // window.location.href = "/autopoolv3/0"

        } else {
            window.$("#join_modal").modal('hide');
            // window.location.href = "/autopoolv3/0"

        }
    }


    const validPositive = (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };

    return (
        <>
            <div
                className="modal fade primary_modal success"
                id="join_modal"
                tabIndex={-1}
                aria-labelledby="connect_walletModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connect_walletModalLabel">
                                Confirm join
                            </h5>

                            {/* <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button> */}
                        </div>
                        <div className="modal-body">
                            <div>
                                <div class="w-100 input_box text-center mb-2">
                                    {/* <span>Join amount {joinAmount} USDT</span> */}
                                </div>
                                <div className="text-center mt-3">
                                    <label>Referral Address</label>
                                    <input
                                        placeholder="Referral Address"
                                        className="primary-input"
                                        name="referral"
                                        onChange={HandleChange}
                                        value={referral}
                                        disabled={address == undefined ? false : true}

                                    />
                                    <label>Amount</label>
                                    <input
                                        placeholder="Amount "
                                        className="primary-input"
                                        name="amount"
                                        onChange={HandleChange}
                                        onInput={validPositive}
                                        value={amount}
                                    />

                                    <button
                                        className="primary_btn"
                                        onClick={() => handleJoin()}
                                        disabled={(isDisable) ? true : false}
                                    >Confirm
                                        {isDisable && <i className="fas fa-spinner fa-spin"></i>}
                                    </button>

                                </div>
                                {isDisable && <span className="text-center d-block">Please do not refresh the page, after confirm the payment.!</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
