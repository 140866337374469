import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import { WagmiConfig } from "wagmi";
import { Provider } from "react-redux";
import store from "./store";
import { createConfig, configureChains } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { bsc, bscTestnet, sepolia } from "wagmi/chains";
import { SnackbarProvider } from "notistack";

import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import Dashboard from "./pages/Dashboard/dashboard";
import Dopamine from "./pages/Dopamine/dopamine";
import DopamineDetail from "./pages/Dopamine/dopamine-detail";
import Joinnow from "./pages/JoinNow/joinnow";
import Autopool from "./pages/autopool/autopool";
import Settings from "./pages/settings/settings";
import Autopoolv2 from "./pages/autopoolv2/autopoolv2";
import Dashboard_v1 from "./pages/autopoolv3/autopoolv3";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet, sepolia],
  [
    infuraProvider({
      apiKey: "cb89ebb21cdccb2e1b591e189e27706a"
    }),
    publicProvider()
  ]
);
const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "cb89ebb21cdccb2e1b591e189e27706a",
        version: 2
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});
const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Joinnow /> },
    { path: "dashboard", element: <Dashboard /> },
    { path: "autopoolv3/:id", element: <Dashboard_v1 /> },
    { path: "autopoolv3", element: <Dashboard_v1 /> },
    { path: "join-now/:address", element: <Dashboard_v1 /> },
    { path: "dopamine", element: <Dopamine /> },
    { path: "dopamine-detail/:id", element: <DopamineDetail /> },
    { path: "autopooliskd728jsnak382msmakncvae", element: <Autopool /> },
    { path: "autopoolv2k6byep90udetbaj321krlkie", element: <Autopoolv2 /> },
    { path: "settingsdesire63HSw8nhkosbgrdg", element: <Settings /> },
    // { path: "autopool", element: <Autopool /> },
    { path: "join-now", element: <Joinnow /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <SnackbarProvider />
      <Provider store={store}>
        <WagmiConfig config={wconfigInitial}>
          <App />
        </WagmiConfig>
      </Provider>
    </Router>
  );
};

export default AppWrapper;
