import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";



import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import ConnectWallet from "../../components/ConnectWallet";

import {
  validation,
  approveToken,
  JoinPlan,
  getReferalAddr,
  checkUser
} from "../JoinNow/joinnowdetail";
import { toastAlert } from "../../helper/toastAlert";
import { isAddress } from "../../helper/custom";
import { checkUsers } from "../../Api/Actions"

export default function Joinnow(props) {

  const walletConnection = useSelector((state) => state.walletConnection);
  const navigate = useNavigate();

  const [ref, setref] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [error, seterror] = useState("");
  const [error1, seterror1] = useState("Please confirm, You will placed in the left position");
  const [isDisable, setisDisable] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  const [isEmail, setisEmail] = useState(true);
  const [phoneCode, setphoneCode] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [isType, setisType] = useState("left");
  const [termsCond, settermsCond] = useState(false);


  useEffect(() => {
    loadRef();
    //eslint-disable-next-line
  }, [walletConnection]);

  async function loadRef() {
    seterror("");
    var resp = getReferalAddr("ref");
    if (isAddress(resp)) {
      setref(resp);
    }
    var isjoin = getReferalAddr("isjoin");

    if (isjoin == "left" || isjoin == "right") {
      setisType(isjoin);
    }


  }

  async function submitReg() {
    console.log("submitReg123")
    setisDisable(true);
    seterror("");
    try {

      var type = 1;
      var sign = email;
      if (isMobile == true) {
        type = 2;
        sign = phoneNo;
      }

      var { errorvalidate, isAllowance, joinAmt } = await validation(ref, sign, type, phoneCode);
      if (errorvalidate && errorvalidate !== "") {
        seterror(errorvalidate);
        setisDisable(false)
        return;
      }

      seterror1("");
      console.log(termsCond, 'termsCondtermsCond')
      if (!termsCond) {
        var txt = (isType == "left") ? "Please confirm, You will placed in the left position" : "Please confirm, You will placed in the right position"
        seterror1(txt);
        setisDisable(false)
        toastAlert("error", txt);
        return
      }


      if (isAllowance) {
        var { isAllowed, approvalAmt, error } = await approveToken();
        if (error && error !== "") {
          toastAlert("error", error);
          setisDisable(false)
          return;
        } else if (isAllowed && approvalAmt >= joinAmt) {
          toastAlert("success", "Successfully approved");
          isAllowance = false;
        } else {
          if (approvalAmt < joinAmt) {
            toastAlert("error", "Insufficient approved USDT");
          } else {
            toastAlert("error", "Failed to approved");
          }
          setisDisable(false)
          return;
        }

      }


      if (!isAllowance) {

        var data = {
          isMobile,
          isEmail,
          ref,
          joinAmt,
          email,
          phoneNo,
          phoneCode,
          isType
        }

        var { errMsg, result } = await JoinPlan(data);
        if (errMsg && errMsg !== "") {
          seterror(errMsg);
          toastAlert("error", errMsg);
        } else {
          toastAlert("success", "Congratulations successfully registered");
          navigate("/dashboard")
        }
      }

      setisDisable(false)
    } catch (err) {
      console.log(err, 'errerrerr')
      toastAlert("error", "Please try again later");
      setisDisable(false)
    }
  }

  async function LoginNow() {
    var { isExist } = await checkUser();
    if (isExist) {
      navigate("/dashboard")
    } else {
      toastAlert("error", "Please Register and continue");
    }

  }

  function SelectType(type) {
    if (type == "mobile") {
      setisMobile(true);
      setisEmail(false);
    } else {
      setisMobile(false);
      setisEmail(true);
    }

  }

  const handlePhoneNumber = (value, country) => {
    const { dialCode } = country;
    var newPhoneNo = value;
    newPhoneNo = newPhoneNo.slice(dialCode.length);
    setphoneCode(dialCode)
    setphoneNo(newPhoneNo);
  };

  const confirmPosition = event => {
    console.log(event.target.checked)
    settermsCond(event.target.checked);
  }

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="t_flx py-5 mt-3 d-block text-center box" >
                <h6 className="mb-4 pb-2" >Join now</h6>
                <div className="row" >
                  <div className="col-lg-6 mx-auto">
                    <div className="tabs mb-3">
                      <div className="d-flex">
                        <div className="me-2">
                          <button className={(isMobile) ? "primary_btn" : "primary_btn active"} onClick={() => SelectType("email")}>Email</button>
                        </div>
                        <div>
                          <button className={(isEmail) ? "primary_btn" : "primary_btn active"} onClick={() => SelectType("mobile")}>Mobile</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isEmail ?
                  <div className="row" >
                    <div className="col-lg-6 text-start mx-auto" >
                      <label className="mb-2" >Email ID</label>
                      <div class="input-group mt-0 mb-3 w-100">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Email ID"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={e => setemail(e.target.value)}
                          value={email}
                        />
                      </div>
                    </div>
                  </div> :

                  <div className="row" >
                    <div className="col-lg-6 text-start mx-auto" >
                      <label className="mb-2" >Mobile Number</label>
                      <div class="input-group mt-0 mb-3 w-100">
                        {/* <input
                          type="text"
                          class="form-control"
                          placeholder="Mobile Number"
                          aria-label="Mobile Number"
                          aria-describedby="basic-addon2"
                          onChange={e => setmobile(e.target.value)}
                          value={email}
                        /> */}
                        <div className="mobile" >
                          <PhoneInput
                            className="input-group regGroupInput mt-2"
                            placeholder="Enter Mobile No"
                            value={phoneCode + phoneNo}
                            onChange={handlePhoneNumber}
                            // onBlur={handleBlurPhone}
                            specialLabel={false}
                            enableSearch={true}
                            country={"in"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }

                <div className="row" >
                  <div className="col-lg-6 text-start mx-auto mb-3" >
                    <label className="mb-2" >Referral ID</label>
                    <div class="input-group mt-0 mb-0 w-100">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Referral ID"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={e => setref(e.target.value)}
                        value={ref}
                      />
                    </div>
                    {error && error !== "" && <p style={{ color: 'red' }} className="m-0 text-start">{error}</p>}

                  </div>
                </div>

                <div className="row" >
                  <div className="col-lg-6 text-start mx-auto" >
                    {/* <label className="mb-2" >Select Position</label> */}
                    <div class="flex-input-group mt-0 mb-3 w-100">
                      <div className="radio-group">

                        {/* <input
                        id="leftLeg"
                        type="radio"
                        name="isJoin"
                        checked={(isType == "left") ? true : false}

                      />
                      <label className="mb-2" for="leftLeg">Left</label> */}
                        <label className={(isType == "left") ? "label active" : "label"}>
                          <input value="value-2" name="value-radio" id="value-2" className="radio-input" type="radio" checked={(isType == "left") ? true : false} />
                          <div className="radio-design"></div>
                          <div className="label-text">Left Position</div>
                        </label>
                      </div>
                      <div className="radio-group">
                        {/* <input
                        id="rightLeg"
                        type="radio"
                        name="isJoin"
                        checked={(isType == "right") ? true : false}

                      />
                        <label className="mb-2" for="rightLeg">Right</label> */}
                        <label className={(isType == "right") ? "label active" : "label"}>
                          <input value="value-3" name="value-radio" id="value-3" className="radio-input" type="radio" checked={(isType == "right") ? true : false} />
                          <div className="radio-design"></div>
                          <div className="label-text">Right Position</div>
                        </label>
                      </div>
                    </div>

                    <div class="form-check checkbox_tick">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={confirmPosition} />
                      <label class="form-check-label" for="flexCheckDefault">
                        {isType == "right" ? "You will placed in the right" : "You will placed in the left"}
                      </label>

                    </div>
                    {/* {error1 && error1 !== "" && <p style={{ color: 'red' }} className="m-0 text-start">{error1}</p>} */}
                  </div>
                </div>


                {walletConnection && walletConnection.address !== "" && walletConnection.connect === "yes" ?
                  <div className="btnalign" >
                    <div className="d-flex mb-3" >
                      <button
                        className="primary_btn"
                        disabled={isDisable}
                        onClick={() => submitReg()}
                      >Submit{isDisable && <i className="fas fa-spinner fa-spin"></i>}
                      </button>
                      <a href="#/" className="primary_btn  ms-3 m-0" onClick={() => LoginNow()}>Login</a>
                    </div>
                    <span>Join amount 25 USDT</span>
                    {isDisable && <span className="text-center">Please do not refresh the page, after confirm the payment.!</span>}
                  </div>
                  :
                  <button
                    type="button"
                    className="primary_btn mt-4"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    Connect wallet
                  </button>
                }

              </div>
            </div>
          </div>

        </div>
      </div>
      <ConnectWallet />
      <Footer />
    </div>
  );
}
