import { Multicall } from "ethereum-multicall";
import BEP20ABI from "../../ABI/BEP20.json";
import AUTOPOOLV2ABI from "../../ABI/AUTOPOOLV2.json";
import AUTOPOOLV1ABI from "../../ABI/AUTOPOOL.json";
import { saveTx } from "../../Api/Actions";
import config from "../../config/config";

import { getFormatMulticall1, shortText, formatNumber, getFormatMulticall } from "../../helper/custom";
import { connection } from "../../helper/connection";
import { getAllowanceValue } from "../../helper/common";
import { convert } from "../../helper/convert";

export function getInitialautopool() {

    return {
        joinAmount: 0,
        isJoined: false,
        isComplete: false,
        retopupCount: 0,
        totalInvest: 0,
        totalClaimed: 0,
        earnedamount: 0,
        userIds: [],
        isExistAutoPool1: false
    }
}

export async function getAutoPoolInfo() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontractv2,
                abi: AUTOPOOLV2ABI,
                calls: [
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "isExistUser",
                        methodName: "isExistUser",
                        methodParameters: [address],
                    },
                    {
                        reference: "getAllusersIds",
                        methodName: "getAllusersIds",
                        methodParameters: [address],
                    },
                    {
                        reference: "totalClaimed",
                        methodName: "totalClaimed",
                        methodParameters: [address],
                    },
                    {
                        reference: "claimableAmount",
                        methodName: "claimableAmount",
                        methodParameters: [address],
                    },
                    {
                        reference: "totalAmount",
                        methodName: "totalAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "minContractBalance",
                        methodName: "minContractBalance",
                        methodParameters: [],
                    }

                ]
            },
            {
                reference: "contractBalance",
                contractAddress: config.tokenAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [config.autoPoolcontractv2],
                    }
                ]
            },
            {
                reference: "autopool1",
                contractAddress: config.autoPoolcontract,
                abi: AUTOPOOLV1ABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    }
                ]
            }
        ];

        const results = await multicall.call(Contract);
        var joinAmount = await getFormatMulticall1(results, "userinfo", 0);
        var isExistUser = await getFormatMulticall1(results, "userinfo", 1);
        var getAllusersIds = await getFormatMulticall1(results, "userinfo", 2);
        var totalClaimed = await getFormatMulticall1(results, "userinfo", 3);
        var claimableAmount = await getFormatMulticall1(results, "userinfo", 4);
        var totalAmount = await getFormatMulticall1(results, "userinfo", 5);
        var minContractBalance = await getFormatMulticall1(results, "userinfo", 6);

        minContractBalance = (minContractBalance && minContractBalance[0] && minContractBalance[0].hex) ? parseInt(minContractBalance[0].hex) : 0;

        var contractBalance = await getFormatMulticall1(results, "contractBalance", 0);
        contractBalance = (contractBalance && contractBalance[0] && contractBalance[0].hex) ? parseInt(contractBalance[0].hex) : 0;

        var isJoined = (isExistUser && isExistUser[0]) ? isExistUser[0] : false;

        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? parseInt(joinAmount[0].hex) : 0;
        var totalInvest = joinAmount * getAllusersIds.length;
        joinAmount = formatNumber(joinAmount / 10 ** 18, 5);

        totalClaimed = (totalClaimed && totalClaimed[0] && totalClaimed[0].hex) ? totalClaimed[0].hex : 0;
        totalClaimed = formatNumber(totalClaimed / 10 ** 18, 5);

        claimableAmount = (claimableAmount && claimableAmount[0] && claimableAmount[0].hex) ? claimableAmount[0].hex : 0;
        claimableAmount = formatNumber(claimableAmount / 10 ** 18, 5);

        if (minContractBalance > contractBalance) {
            claimableAmount = 0;
        }

        totalInvest = formatNumber(totalInvest / 10 ** 18, 5);

        var isExistAutoPool1 = await getFormatMulticall1(results, "autopool1", 0);
        isExistAutoPool1 = (isExistAutoPool1 && isExistAutoPool1[0]) ? isExistAutoPool1[0] : false

        return {
            joinAmount,
            isJoined,
            isComplete: false,
            retopupCount: (getAllusersIds && getAllusersIds.length > 0) ? getAllusersIds.length - 1 : 0,
            totalInvest: totalInvest,
            totalClaimed: totalClaimed,
            earnedamount: claimableAmount,
            userIds: getAllusersIds,
            isExistAutoPool1: isExistAutoPool1
        }
    } catch (err) {
        console.log(err, 'errerrerr2222')
        return getInitialautopool()
    }

}

export function getInitialJoinInfos() {

    return {
        result: [],
        childCount: 0,
        id: 0,
        rebirthid: 0,
        isReach: false,
        totalChild: 0
    }
}

export async function getJoinInfos(userIds) {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract1 = [];
        for (var u = 0; u < userIds.length > 0; u++) {
            var uId = (userIds && userIds[u
            ] && userIds[u].hex) ? parseInt(userIds[u].hex) : 0;

            Contract1.push({
                reference: "userInfo-" + u,
                contractAddress: config.autoPoolcontractv2,
                abi: AUTOPOOLV2ABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [uId],
                    }
                ]
            });
        }
        const results1 = await multicall.call(Contract1);

        var uList = [];
        var totalChild = 0;
        for (var r1 = 0; r1 < userIds.length; r1++) {

            var users = await getFormatMulticall1(results1, "userInfo-" + r1, 0);

            var id = (users && users[0] && users[1].hex) ? parseInt(users[1].hex) : 0;
            var childCount = (users && users[5] && users[5].hex) ? parseInt(users[5].hex) : 0;
            var isReach = (users && users[2]) ? users[2] : false;
            var parentAddress = (users && users[4]) ? users[4] : "";
            var joinedTime = (users && users[6] && users[6].hex) ? parseInt(users[6].hex) * 1000 : 0;
            joinedTime = new Date(joinedTime);

            totalChild += childCount;

            uList.push({
                id,
                childCount,
                isReach,
                parentAddress,
                joinedTime
            })
        }
        var currentData = uList[uList.length - 1];
        var childCount = (currentData && currentData.childCount) ? currentData.childCount : 0;
        var rebirthid = (currentData && currentData.id && uList.length > 1) ? currentData.id : 0;
        var isReach = (currentData && currentData.isReach) ? currentData.isReach : false;
        var id = (uList && uList[0] && uList[0].id) ? uList[0].id : 0;
        return {
            result: uList,
            childCount,
            id,
            rebirthid,
            isReach,
            totalChild
        };
    } catch (err) {
        console.log(err, 'getJoinInfos')
        return getInitialJoinInfos();
    }
}

export async function getUserInfo() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        var Contract = new web3.eth.Contract(AUTOPOOLV2ABI, config.autoPoolcontractv2);
        var user = await Contract.methods.users(address).call();

        return {
            user
        }
    } catch (err) {
        console.log(err, 'errerrerr3333')
        return {
            user: {}
        }
    }

}



export async function Claimreward() {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var balance = await web3.eth.getBalance(address);
        balance = balance / 10 ** 18;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(AUTOPOOLV2ABI, config.autoPoolcontractv2);

        var TokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);

        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var claimableAmount = await Contract.methods.claimableAmount(address).call();
        claimableAmount = (claimableAmount) ? claimableAmount : 0;
        var earnAmt = claimableAmount / 10 ** 18;

        var balanceOf1 = await TokenContract.methods.balanceOf(config.autoPoolcontractv2).call();

        if (parseFloat(balanceOf1) < parseFloat(claimableAmount)) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Insufficient reward`
            }
        }

        if (earnAmt == 0) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Insufficient reward`
            }
        }

        var { fee,
            allowance,
            balanceOf } = await getClaimInfo();

        if (parseFloat(balanceOf) < parseFloat(fee)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have fee(${parseFloat(fee)} QBT) in your wallet.`
            }
        }


        if (parseFloat(allowance) < parseFloat(fee)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.QbtAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.autoPoolcontractv2,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.autoPoolcontractv2,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < fee) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }

        var estimateGas = await Contract.methods.claimRewards().estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.claimRewards().send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        var hash = (result && result.transactionHash) ? result.transactionHash : ""
        var data = {
            address: address,
            amount: earnAmt,
            txtype: "claim",
            hash
        }
        await saveTx(data)

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash,
            error: (result && result.status) ? "" : "Failed to claimed"
        }
    } catch (err) {
        console.log(err, 'errerrerrerr4444')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to claimed"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg
        }
    }
}


export async function Rejoin(amount) {
    try {

        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var { balanceOf, allowance, bnbBal } = await getAllowanceValue(config.autoPoolcontractv2)

        var address = get.address;
        // var balance = await web3.eth.getBalance(address);
        var balance = bnbBal;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(AUTOPOOLV2ABI, config.autoPoolcontractv2);

        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var Contract1 = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontractv2,
                abi: AUTOPOOLV2ABI,
                calls: [
                    {
                        reference: "isExistUser",
                        methodName: "isExistUser",
                        methodParameters: [address],
                    },
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "getAllusersIds",
                        methodName: "getAllusersIds",
                        methodParameters: [address],
                    },

                ]
            }
        ];


        const results = await multicall.call(Contract1);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);

        var isExistUser = await getFormatMulticall1(results, "userinfo", 0);
        var isExits = (isExistUser && isExistUser[0]) ? isExistUser[0] : false;

        var getAllusersIds = await getFormatMulticall1(results, "userinfo", 2);
        var lastId = getAllusersIds[getAllusersIds.length - 1];

        var users = await Contract.methods.users(lastId).call();
        var isReach = (users && users.isReach) ? users.isReach : false;

        if (!isReach || !isExits) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Unable to proceed re join`
            }
        }

        var joinAmount = await getFormatMulticall1(results, "userinfo", 1);
        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? parseInt(joinAmount[0].hex) : 0;
        joinAmount = joinAmount.toString()
        var amt1 = parseFloat(joinAmount) / 10 ** 18;

        joinAmount = await convert(joinAmount);

        if (parseFloat(balanceOf) < parseFloat(amt1)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have ${amt1} USDT in your wallet`
            }
        }



        if (parseFloat(allowance) < parseFloat(amt1)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.autoPoolcontractv2,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.autoPoolcontractv2,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < amt1) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }

        var estimateGas = await Contract.methods.reTopup(joinAmount).estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.reTopup(joinAmount).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        var hash = (result && result.transactionHash) ? result.transactionHash : ""

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash,
            error: (result && result.status) ? "" : "Failed to rejoin",
            amount: amt1
        }
    } catch (err) {
        console.log(err, 'Rejoin')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to rejoin"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg,
            amount: 0
        }
    }
}
export async function joinPool() {
    try {

        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var { balanceOf, allowance, bnbBal } = await getAllowanceValue(config.autoPoolcontractv2)

        var address = get.address;
        // var balance = await web3.eth.getBalance(address);
        var balance = bnbBal;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(AUTOPOOLV2ABI, config.autoPoolcontractv2);

        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var Contract1 = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontractv2,
                abi: AUTOPOOLV2ABI,
                calls: [
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "isExistUser",
                        methodName: "isExistUser",
                        methodParameters: [address],
                    }

                ]
            }
        ];


        const results = await multicall.call(Contract1);

        var isExistUser = await getFormatMulticall1(results, "isExistUser", 1);
        var isExits = (isExistUser && isExistUser[0]) ? isExistUser[0] : false;

        if (isExits) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Already activated pool`
            }
        }

        var joinAmount = await getFormatMulticall1(results, "userinfo", 0);
        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? parseInt(joinAmount[0].hex) : 0;
        joinAmount = joinAmount.toString()
        var amt1 = parseFloat(joinAmount) / 10 ** 18;

        joinAmount = await convert(joinAmount);

        if (parseFloat(balanceOf) < parseFloat(amt1)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have ${amt1} USDT in your wallet`
            }
        }



        if (parseFloat(allowance) < parseFloat(amt1)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.autoPoolcontractv2,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.autoPoolcontractv2,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < amt1) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }

        var estimateGas = await Contract.methods.joinPool(joinAmount).estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }


        var result = await Contract.methods.joinPool(joinAmount).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        //var topupcommision = await Contract.methods.referAmount().call();
        var hash = (result && result.transactionHash) ? result.transactionHash : ""
        // var data = {
        //     address: address,
        //     amount: parseFloat(amount) / 10 ** 18,
        //     txtype: "topup",
        //     hash,
        //     topupcommision: parseFloat(topupcommision) / 10 ** 18,
        //     directParent: directParent
        // }
        // await saveTx(data)

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash: (result && result.transactionHash) ? result.transactionHash : "",
            error: (result && result.status) ? "" : "Failed to claimed",
            amount: amt1
        }
    } catch (err) {
        console.log(err, 'Rejoin')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to re-join"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg,
            amount: 0
        }
    }
}

export async function getClaimInfo() {


    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "getTokenFee",
                contractAddress: config.autoPoolcontractv2,
                abi: AUTOPOOLV2ABI,
                calls: [
                    {
                        reference: "getTokenFee",
                        methodName: "getTokenFee",
                        methodParameters: [],
                    },

                ]
            },
            {
                reference: "tokenInfo",
                contractAddress: config.QbtAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "allowance",
                        methodName: "allowance",
                        methodParameters: [address, config.autoPoolcontractv2],
                    },
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    },
                    {
                        reference: "symbol",
                        methodName: "symbol",
                        methodParameters: [],
                    },
                ]
            }
        ];

        const results = await multicall.call(Contract);
        var getTokenFee = await getFormatMulticall1(results, "getTokenFee", 0);
        getTokenFee = (getTokenFee && getTokenFee[0] && getTokenFee[0].hex) ? parseInt(getTokenFee[0].hex) / 10 ** 18 : 0;

        var allowance = await getFormatMulticall1(results, "tokenInfo", 0);
        allowance = (allowance && allowance[0] && allowance[0].hex) ? parseInt(allowance[0].hex) / 10 ** 18 : 0;

        var balanceOf = await getFormatMulticall1(results, "tokenInfo", 1);

        balanceOf = (balanceOf && balanceOf[0] && balanceOf[0].hex) ? parseInt(balanceOf[0].hex) / 10 ** 18 : 0;

        var symbol = await getFormatMulticall1(results, "tokenInfo", 2);

        symbol = (symbol && symbol[0]) ? symbol[0] : "";

        return {
            fee: getTokenFee,
            allowance,
            balanceOf,
            symbol,
        }
    } catch (err) {
        console.log(err, 'errerrerr111')
        return {
            fee: 0,
            allowance: 0,
            balanceOf: 0,
            symbol: "",
        }
    }

}