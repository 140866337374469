import { Multicall } from "ethereum-multicall";
import BEP20ABI from "../../ABI/BEP20.json";
import AUTOPOOLABI from "../../ABI/AUTOPOOL.json";
import { saveTx } from "../../Api/Actions";
import config from "../../config/config";

import { getFormatMulticall1, shortText, formatNumber } from "../../helper/custom";
import { connection } from "../../helper/connection";
import { getAllowanceValue } from "../../helper/common";
import { convert } from "../../helper/convert";

export function getInitialautopool() {

    return {
        isJoined: false,
        retopupcnt: 0,
        advancepayment: 0,
        usedamount: 0,
        balanceamount: 0,
        earnedamount: 0,
        autoPoolReached: false,
        parentId: 0,
        joinAmount: 0,
        isClaimAllow: false,
        isClaim: false,
        id: 0,
        isComplete: false,
        totalClaimed: 0
    }
}

export async function getAutoPoolInfo() {


    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var text = shortText(address);

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontract,
                abi: AUTOPOOLABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    },
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "isClaimAllow",
                        methodName: "isClaimAllow",
                        methodParameters: [address],
                    },

                ]
            }
        ];

        const results = await multicall.call(Contract);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        var autoPoolReached = await getFormatMulticall1(results, "userinfo", 1);
        autoPoolReached = (autoPoolReached && autoPoolReached[0]) ? autoPoolReached[0] : false;

        var parentAddress = (userinfo && userinfo[2]) ? userinfo[2] : "";
        var id = (userinfo && userinfo[1] && userinfo[1].hex) ? parseInt(userinfo[1].hex) : 0;

        var isJoined = (userinfo && userinfo[0]) ? userinfo[0] : false;
        var retopupcnt = (userinfo && userinfo[5] && userinfo[5].hex) ? parseInt(userinfo[5].hex) : 0;

        var advancepayment = (userinfo && userinfo[10] && userinfo[10].hex) ? parseInt(userinfo[10].hex) : 0;
        advancepayment = formatNumber(advancepayment / 10 ** 18, 5);

        var usedamount = (userinfo && userinfo[11] && userinfo[11].hex) ? parseInt(userinfo[11].hex) : 0;
        usedamount = formatNumber(usedamount / 10 ** 18, 5);

        var balanceamount = advancepayment - usedamount;
        balanceamount = formatNumber(balanceamount, 5);

        var earnedamount = (userinfo && userinfo[6] && userinfo[6].hex) ? parseInt(userinfo[6].hex) : 0;
        earnedamount = formatNumber(earnedamount / 10 ** 18, 5);

        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontract);
        var user = await Contract.methods.users(parentAddress).call();
        var refId = user.id;

        var joinAmount = await getFormatMulticall1(results, "userinfo", 1);
        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? joinAmount[0].hex : 0;
        joinAmount = formatNumber(joinAmount / 10 ** 18, 5);

        var isClaimAllow = await getFormatMulticall1(results, "userinfo", 2);
        isClaimAllow = (isClaimAllow && isClaimAllow[0]) ? isClaimAllow[0] : false;

        var totalClaimed = (userinfo && userinfo[8] && userinfo[8].hex) ? parseInt(userinfo[8].hex) : 0;
        totalClaimed = formatNumber(totalClaimed / 10 ** 18, 5);

        var isClaim = false;
        if (earnedamount >= 30 && isClaimAllow) {
            isClaim = true;
        }

        //var isComplete = await getFormatMulticall1(results, "userinfo", 9);
        var isComplete = (userinfo && userinfo[9]) ? userinfo[9] : false;



        return {
            isJoined,
            retopupcnt,
            advancepayment,
            usedamount,
            balanceamount,
            earnedamount,
            autoPoolReached,
            parentId: (refId) ? refId : 0,
            joinAmount,
            isClaimAllow,
            isClaim: isClaim,
            id,
            isComplete,
            totalClaimed
        }
    } catch (err) {
        console.log(err, 'errerrerr2222')
        return getInitialautopool()
    }

}

export async function getUserInfo() {

    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontract);
        var user = await Contract.methods.users(address).call();

        return {
            user
        }
    } catch (err) {
        console.log(err, 'errerrerr3333')
        return {
            user: {}
        }
    }

}



export async function Claimreward() {
    try {

        var get = await connection();
        var web3 = get.web3;
        var address = get.address;
        var balance = await web3.eth.getBalance(address);
        balance = balance / 10 ** 18;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontract);

        var TokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);

        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var user = await Contract.methods.users(address).call();
        var claimable = (user && user.claimable) ? user.claimable : 0;
        var earnAmt = claimable / 10 ** 18;

        var balanceOf1 = await TokenContract.methods.balanceOf(config.autoPoolcontract).call();
        // console.log(earnAmt, balanceOf, "???????????")
        // if (parseFloat(balanceOf1) < parseFloat(claimable)) {
        //     return {
        //         isclaim: false,
        //         hash: "",
        //         error: `Sorry, Insufficient reward`
        //     }
        // }

        if (earnAmt == 0) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Insufficient reward`
            }
        }

        var { fee,
            allowance,
            balanceOf, minimumwithdraw } = await getClaimInfo();

        if (parseFloat(balanceOf) < parseFloat(fee)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have fee(${parseFloat(fee)} QBT) in your wallet.`
            }
        }

        if (parseFloat(earnAmt) < parseFloat(minimumwithdraw)) {
            return {
                isclaim: false,
                hash: "",
                error: `Minimum withdraw balance ${minimumwithdraw} USDT`
            }
        }

        if (parseFloat(allowance) < parseFloat(fee)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.QbtAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < fee) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }

        var estimateGas = await Contract.methods.claimRewards().estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }

        var result = await Contract.methods.claimRewards().send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        var hash = (result && result.transactionHash) ? result.transactionHash : ""
        var data = {
            address: address,
            amount: earnAmt,
            txtype: "claim",
            hash
        }
        await saveTx(data)

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash,
            error: (result && result.status) ? "" : "Failed to claimed"
        }
    } catch (err) {
        console.log(err, 'errerrerrerr4444')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to claimed"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg
        }
    }
}


export async function Rejoin(amount) {
    try {

        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var { balanceOf, allowance, bnbBal } = await getAllowanceValue(config.autoPoolcontract)

        var address = get.address;
        // var balance = await web3.eth.getBalance(address);
        var balance = bnbBal;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontract);
        console.log(balance, 'balancebalance')
        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var Contract1 = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontract,
                abi: AUTOPOOLABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    },
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "autoPoolReached",
                        methodName: "autoPoolReached",
                        methodParameters: [address],
                    },

                ]
            }
        ];


        const results = await multicall.call(Contract1);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        var autoPoolReached = await getFormatMulticall1(results, "userinfo", 2);
        autoPoolReached = (userinfo && userinfo[0]) ? userinfo[0] : "";


        if (!autoPoolReached) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Unable to proceed re join`
            }
        }

        var joinAmount = await getFormatMulticall1(results, "userinfo", 1);
        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? parseInt(joinAmount[0].hex) : 0;
        joinAmount = joinAmount.toString()
        var amt1 = parseFloat(joinAmount) / 10 ** 18;

        joinAmount = await convert(joinAmount);

        console.log(balanceOf, 'balanceOf', amt1)
        console.log(amt1, 'amt1')
        console.log(allowance, 'allowance', amt1)

        if (parseFloat(balanceOf) < parseFloat(amt1)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have ${amt1} USDT in your wallet`
            }
        }



        if (parseFloat(allowance) < parseFloat(amt1)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < amt1) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }

        var estimateGas = await Contract.methods.advanceTopup(joinAmount).estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }


        var result = await Contract.methods.advanceTopup(joinAmount).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        //var topupcommision = await Contract.methods.referAmount().call();
        var hash = (result && result.transactionHash) ? result.transactionHash : ""
        // var data = {
        //     address: address,
        //     amount: parseFloat(amount) / 10 ** 18,
        //     txtype: "topup",
        //     hash,
        //     topupcommision: parseFloat(topupcommision) / 10 ** 18,
        //     directParent: directParent
        // }
        // await saveTx(data)

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash: (result && result.transactionHash) ? result.transactionHash : "",
            error: (result && result.status) ? "" : "Failed to rejoin",
            amount: amt1
        }
    } catch (err) {
        console.log(err, 'Rejoin')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to rejoin"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg,
            amount: 0
        }
    }
}
export async function joinPool() {
    try {

        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var { balanceOf, allowance, bnbBal } = await getAllowanceValue(config.autoPoolcontract)

        var address = get.address;
        // var balance = await web3.eth.getBalance(address);
        var balance = bnbBal;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontract);
        console.log(balance, 'balancebalance')
        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var Contract1 = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontract,
                abi: AUTOPOOLABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    },
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    }

                ]
            }
        ];


        const results = await multicall.call(Contract1);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        var isExits = (userinfo && userinfo[0]) ? userinfo[0] : false;

        if (isExits) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Already activated pool`
            }
        }

        var joinAmount = await getFormatMulticall1(results, "userinfo", 1);
        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? parseInt(joinAmount[0].hex) : 0;
        joinAmount = joinAmount.toString()
        var amt1 = parseFloat(joinAmount) / 10 ** 18;

        joinAmount = await convert(joinAmount);

        console.log(balanceOf, 'balanceOf', amt1)
        console.log(amt1, 'amt1')
        console.log(allowance, 'allowance', amt1)

        if (parseFloat(balanceOf) < parseFloat(amt1)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have ${amt1} USDT in your wallet`
            }
        }



        if (parseFloat(allowance) < parseFloat(amt1)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < amt1) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }

        var estimateGas = await Contract.methods.joinPool(joinAmount).estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }


        var result = await Contract.methods.joinPool(joinAmount).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });
        //var topupcommision = await Contract.methods.referAmount().call();
        var hash = (result && result.transactionHash) ? result.transactionHash : ""
        // var data = {
        //     address: address,
        //     amount: parseFloat(amount) / 10 ** 18,
        //     txtype: "topup",
        //     hash,
        //     topupcommision: parseFloat(topupcommision) / 10 ** 18,
        //     directParent: directParent
        // }
        // await saveTx(data)

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash: (result && result.transactionHash) ? result.transactionHash : "",
            error: (result && result.status) ? "" : "Failed to claimed",
            amount: amt1
        }
    } catch (err) {
        console.log(err, 'Rejoin')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to re-join"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg,
            amount: 0
        }
    }
}

export async function AdvancePay(amount) {
    try {

        var get = await connection();
        var web3 = get.web3;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var { balanceOf, allowance, bnbBal } = await getAllowanceValue(config.autoPoolcontract)

        var address = get.address;
        // var balance = await web3.eth.getBalance(address);
        var balance = bnbBal;
        var gasPrice = await web3.eth.getGasPrice();
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontract);
        console.log(balance, 'balancebalance')
        if (balance === 0) {
            return {
                isclaim: false,
                hash: "",
                error: "Please make sure you have gas fee in your wallet."
            }
        }

        var Contract1 = [
            {
                reference: "userinfo",
                contractAddress: config.autoPoolcontract,
                abi: AUTOPOOLABI,
                calls: [
                    {
                        reference: "users",
                        methodName: "users",
                        methodParameters: [address],
                    },
                    {
                        reference: "joinAmount",
                        methodName: "joinAmount",
                        methodParameters: [],
                    },
                    {
                        reference: "isClaimAllow",
                        methodName: "isClaimAllow",
                        methodParameters: [address],
                    },

                ]
            }
        ];


        const results = await multicall.call(Contract1);
        var userinfo = await getFormatMulticall1(results, "userinfo", 0);
        var isClaimAllow = await getFormatMulticall1(results, "userinfo", 2);
        isClaimAllow = (userinfo && userinfo[0]) ? userinfo[0] : false;


        if (!isClaimAllow) {
            return {
                isclaim: false,
                hash: "",
                error: `Sorry, Unable to proceed re join`
            }
        }

        var joinAmount = await getFormatMulticall1(results, "userinfo", 1);
        joinAmount = (joinAmount && joinAmount[0] && joinAmount[0].hex) ? parseInt(joinAmount[0].hex) : 0;

        var amt1 = amount;

        var amount = parseFloat(amount) * 10 ** 18;
        amount = amount.toString();
        amount = await convert(amount);
        console.log(balanceOf, amt1, '****************')
        if (parseFloat(balanceOf) < parseFloat(amt1)) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have ${amt1} USDT in your wallet`
            }
        }
        console.log(allowance, parseFloat(amt1), '????????????')
        if (parseFloat(allowance) < parseFloat(amt1)) {

            var tokenContract = new web3.eth.Contract(BEP20ABI, config.tokenAddress);
            var approveAmt = 100000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var estimateGas = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).estimateGas({ from: address });

            estimateGas = estimateGas + 100000;

            if (parseFloat(estimateGas) / 10 ** 8 > balance) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
                }
            }

            var result = await tokenContract.methods.approve(
                config.autoPoolcontract,
                approveAmt.toString()
            ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

            var approvalAmt = (result && result.events && result.events.Approval && result.events.Approval.returnValues
                && result.events.Approval.returnValues && result.events.Approval.returnValues
                && result.events.Approval.returnValues.value) ?
                parseFloat(result.events.Approval.returnValues.value) : 0
            approvalAmt = approvalAmt / 10 ** 18;
            if (approvalAmt < amt1) {
                return {
                    isclaim: false,
                    approvalAmt: 0,
                    error: `Sorry,Insufficient allowance`
                }
            }

        }

        var estimateGas = await Contract.methods.advanceTopup(amount).estimateGas({ from: address });
        estimateGas = estimateGas + 100000;

        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                isclaim: false,
                hash: "",
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }


        var result = await Contract.methods.advanceTopup(amount).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        return {
            isclaim: (result && result.status) ? result.status : false,
            hash: (result && result.transactionHash) ? result.transactionHash : "",
            error: (result && result.status) ? "" : "Failed to pay",
            amount: amt1
        }
    } catch (err) {
        console.log(err, 'Rejoin')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var errorMsg = "Failed to pay"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0) {
            errorMsg = "Sorry unable to proceed, Please check your wallet in gas fee(BNB)";
        }
        return {
            isclaim: false,
            hash: "",
            error: errorMsg,
            amount: 0
        }
    }
}

export async function getClaimInfo() {


    try {
        var get = await connection();
        var web3 = get.web3;
        var address = get.address;

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "getTokenFee",
                contractAddress: config.autoPoolcontract,
                abi: AUTOPOOLABI,
                calls: [
                    {
                        reference: "getTokenFee",
                        methodName: "getTokenFee",
                        methodParameters: [],
                    },
                    {
                        reference: "lastClaimed",
                        methodName: "lastClaimed",
                        methodParameters: [address],
                    },
                    {
                        reference: "minimumwithdraw",
                        methodName: "minimumwithdraw",
                        methodParameters: [],
                    },
                    {
                        reference: "withdrawtime",
                        methodName: "withdrawtime",
                        methodParameters: [],
                    }

                ]
            },
            {
                reference: "tokenInfo",
                contractAddress: config.QbtAddress,
                abi: BEP20ABI,
                calls: [
                    {
                        reference: "allowance",
                        methodName: "allowance",
                        methodParameters: [address, config.autoPoolcontract],
                    },
                    {
                        reference: "balanceOf",
                        methodName: "balanceOf",
                        methodParameters: [address],
                    },
                    {
                        reference: "symbol",
                        methodName: "symbol",
                        methodParameters: [],
                    },
                ]
            }
        ];

        const results = await multicall.call(Contract);
        var getTokenFee = await getFormatMulticall1(results, "getTokenFee", 0);
        getTokenFee = (getTokenFee && getTokenFee[0] && getTokenFee[0].hex) ? parseInt(getTokenFee[0].hex) / 10 ** 18 : 0;

        var withdrawtime = await getFormatMulticall1(results, "getTokenFee", 3);
        withdrawtime = (withdrawtime && withdrawtime[0] && withdrawtime[0].hex) ? parseInt(withdrawtime[0].hex) : 0;
        console.log(withdrawtime, 'withdrawtimewithdrawtime')
        withdrawtime = 86400 / withdrawtime;
        withdrawtime = 24 / withdrawtime;

        var lastClaimed = await getFormatMulticall1(results, "getTokenFee", 1);

        lastClaimed = (lastClaimed && lastClaimed[0] && lastClaimed[0].hex) ? parseInt(lastClaimed[0].hex) * 1000 : 0;
        lastClaimed = new Date(lastClaimed);
        lastClaimed.setHours(lastClaimed.getHours() + withdrawtime);

        if (lastClaimed < new Date()) {
            lastClaimed = null;
        }

        var minimumwithdraw = await getFormatMulticall1(results, "getTokenFee", 2);
        minimumwithdraw = (minimumwithdraw && minimumwithdraw[0] && minimumwithdraw[0].hex) ? parseInt(minimumwithdraw[0].hex) / 10 ** 18 : 0;


        var allowance = await getFormatMulticall1(results, "tokenInfo", 0);
        allowance = (allowance && allowance[0] && allowance[0].hex) ? parseInt(allowance[0].hex) / 10 ** 18 : 0;

        var balanceOf = await getFormatMulticall1(results, "tokenInfo", 1);

        balanceOf = (balanceOf && balanceOf[0] && balanceOf[0].hex) ? parseInt(balanceOf[0].hex) / 10 ** 18 : 0;

        var symbol = await getFormatMulticall1(results, "tokenInfo", 2);

        symbol = (symbol && symbol[0]) ? symbol[0] : "";

        return {
            fee: getTokenFee,
            allowance,
            balanceOf,
            symbol,
            lastClaimed,
            minimumwithdraw
        }
    } catch (err) {
        console.log(err, 'errerrerr111')
        return {
            fee: 0,
            allowance: 0,
            balanceOf: 0,
            symbol: "",
            lastClaimed: null,
            minimumwithdraw: 0
        }
    }

}